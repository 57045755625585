import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Contract, SupplierContractsList } from './components'
import { ContractsListProvider, OptionsProvider, SupplierContractFormProvider } from './stores'

export const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
          <ContractsListProvider>
            <SupplierContractsList />
          </ContractsListProvider>
        }
        />
        <Route
          path="/form/:supplierId/:contractId?"
          element={
            <SupplierContractFormProvider>
              <OptionsProvider>
                <Contract />
              </OptionsProvider>
            </SupplierContractFormProvider>
          } />
      </Routes>
    </Router>
  )
}
