import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../../stores/SupplierContractForm'
import { getOptionNameById, getOptionNamesByIds } from '../../../../utils'
import { ERebateTargetType, type IUnitRebate, type IUnitRebateBrandRow, type IUnitRebateProductRow } from '../../../../types'
import { type IHeader, SimpleTable } from '../../../commons'
import { useOptions } from '../../../../stores/Options'

export const UnitRebatesSummary: React.FC = () => {
  const { t } = useTranslation()
  const { state } = useSupplierForm()
  const { state: options } = useOptions()
  const contractForm = state.contractForm
  const rebates = contractForm.unitRebates
  const paymentFrequencyId = contractForm.unitRebatesPaymentFrequencyId
  const firstPaymentDate = contractForm.unitRebatesFirstPaymentIssuedDate

  const allSalesHeaders: IHeader[] = [
    {
      key: 'distributionCenters',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.allSales.distributionCenters')
    },
    {
      key: 'measurementUnit',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.allSales.measurementUnit')
    },
    {
      key: 'rebateAmount',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.allSales.rebateAmount')
    }
  ]

  const specificBrandsHeaders: IHeader[] = [
    {
      key: 'distributionCenters',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.distributionCenters')
    },
    {
      key: 'productBrand',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.brand')
    },
    {
      key: 'measurementUnit',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.measurementUnit')
    },
    {
      key: 'rebateAmount',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.rebateAmount')
    }
  ]

  const specificProductsHeaders: IHeader[] = [
    {
      key: 'distributionCenters',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.distributionCenters')
    },
    {
      key: 'GTIN',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.GTIN')
    },
    {
      key: 'productCode',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.productCode')
    },
    {
      key: 'description',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.description')
    },
    {
      key: 'measurementUnit',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.measurementUnit')
    },
    {
      key: 'rebateAmount',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.rebateAmount')
    }
  ]

  const rowsData: Array<Array<Record<string, React.ReactNode>>> = contractForm.unitRebates.map((entry, index) => {
    const rows = Array.isArray(entry.rebateDataRows) ? entry.rebateDataRows : []
    return rows.map((row, indexRow) => ({
      distributionCenters: getOptionNamesByIds(options.distributionCenterOptions, row.distributionCenterIds),
      measurementUnit: getOptionNameById(options.measurementTypeOptionsUnitRebate, row.rebateMeasurementTypeId ?? ''),
      rebateAmount: row.amount,
      productBrand: entry.rebateTargetTypeId === ERebateTargetType.PRODUCT_BRAND ? getOptionNameById(options.brandOptions, (row as IUnitRebateBrandRow).productBrandId ?? '') : null,
      GTIN: entry.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT ? (row as IUnitRebateProductRow).gtin : null,
      productCode: entry.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT ? (row as IUnitRebateProductRow).productCode : null,
      description: entry.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT ? (row as IUnitRebateProductRow).productDescriptionEN : null
    }))
  })

  const renderUnitRebate: (rebate: IUnitRebate, index: number) => React.ReactElement | null = (rebate, index) => {
    const type = rebate.rebateTargetTypeId
    switch (type) {
      case ERebateTargetType.ALL_SALES:
        return (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('supplierContractForm.summary.unitRebates.applicableTo.allSales.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <SimpleTable
                headers={allSalesHeaders}
                rows={rowsData[index]}
              />
            </Grid>
          </>
        )
      case ERebateTargetType.PRODUCT_BRAND:
        return (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('supplierContractForm.summary.unitRebates.applicableTo.brands.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <SimpleTable
                headers={specificBrandsHeaders}
                rows={rowsData[index]}
              />
            </Grid>
          </>
        )
      case ERebateTargetType.SPECIFIC_PRODUCT:
        return (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('supplierContractForm.summary.unitRebates.applicableTo.products.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <SimpleTable
                headers={specificProductsHeaders}
                rows={rowsData[index]}
              />
            </Grid>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Grid container spacing={2}>
      {rebates.map((entry, index) => (
        <>
          <Grid item xs={12}>
            <Typography fontWeight="bold" variant="subtitle1">{`${t('supplierContractForm.summary.unitRebates.subtitle')} ${index + 1}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              {`${t('supplierContractForm.summary.commons.applicableTo')} ${options.foodtasticBrandOptions.length === entry.foodtasticBrandIds.length ? t('supplierContractForm.commons.allFoodtasticBrands') : getOptionNamesByIds(options.foodtasticBrandOptions, entry.foodtasticBrandIds)}`}
              </Typography>
          </Grid>
          {renderUnitRebate(entry, index)}
        </>
      ))}
      <Grid item xs={12}>
        <Typography fontWeight="bold" variant="subtitle1">{t('supplierContractForm.summary.unitRebates.paymentConditions.title')}</Typography>
        <Typography variant="subtitle1">{`${t('supplierContractForm.summary.unitRebates.paymentConditions.payment')}: ${getOptionNameById(options.paymentFrequencyOptions, paymentFrequencyId ?? '') ?? '-'}`}</Typography>
        <Typography variant="subtitle1">{`${t('supplierContractForm.summary.unitRebates.paymentConditions.firstPaymentDate')}: ${firstPaymentDate ?? '-'}`}</Typography>
      </Grid>
    </Grid>
  )
}
