import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { ERebateTargetType, type IOption, type IUnitRebate, type IUnitRebateBrandRow, type IUnitRebateProductRow } from '../../../../types'
import { useTranslation } from 'react-i18next'
import { getOptionNameById, getOptionNamesByIds } from '../../../../utils'

const styles = StyleSheet.create({
  table: {
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8
  },
  header: {
    borderTop: 'none'
  },
  bold: {
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 8,
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  sectionBody: {
    fontSize: 6,
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  },
  section: {
    fontSize: 6,
    marginBottom: 6,
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  },
  allSalesRow1: {
    fontSize: 6,
    width: '34%'
  },
  allSalesRow2: {
    fontSize: 6,
    width: '33%'
  },
  allSalesRow3: {
    fontSize: 6,
    width: '33%'
  },
  brandsRow1: {
    fontSize: 6,
    width: '25%'
  },
  brandsRow2: {
    fontSize: 6,
    width: '25%'
  },
  brandsRow3: {
    fontSize: 6,
    width: '25%'
  },
  brandsRow4: {
    fontSize: 6,
    width: '25%'
  },
  productRows1: {
    fontSize: 6,
    width: '17%'
  },
  productRows2: {
    fontSize: 6,
    width: '17%'
  },
  productRows3: {
    fontSize: 6,
    width: '17%'
  },
  productRows4: {
    fontSize: 6,
    width: '17%'
  },
  productRows5: {
    fontSize: 6,
    width: '16%'
  },
  productRows6: {
    fontSize: 6,
    width: '16%'
  }
})

interface IUnitRebatesExportProps {
  brandOptions: IOption[]
  distributionCenterOptions: IOption[]
  foodtasticBrandOptions: IOption[]
  measurementTypeOptionsUnitRebate: IOption[]
  unitRebate: IUnitRebate
  index: number
}

export const UnitRebatesExport: React.FC<IUnitRebatesExportProps> = (props) => {
  const { t } = useTranslation()
  const {
    brandOptions,
    distributionCenterOptions,
    foodtasticBrandOptions,
    measurementTypeOptionsUnitRebate,
    unitRebate,
    index
  } = props

  const allSalesHeaders: string[] = [
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.allSales.distributionCenters'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.allSales.measurementUnit'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.allSales.rebateAmount')
  ]

  const specificBrandsHeaders: string[] = [
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.distributionCenters'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.brand'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.measurementUnit'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.rebateAmount')
  ]

  const specificProductsHeaders: string[] = [
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.distributionCenters'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.GTIN'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.productCode'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.description'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.measurementUnit'),
    t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.rebateAmount')
  ]

  return (
    <View>
      <Text style={styles.subtitle}>{`${t('supplierContractForm.summary.unitRebates.title')} ${index + 1}:`}</Text>
      <Text style={styles.sectionBody}>{`${t('supplierContractForm.summary.commons.applicableTo')} ${foodtasticBrandOptions.length === unitRebate.foodtasticBrandIds.length ? t('supplierContractForm.commons.allFoodtasticBrands') : getOptionNamesByIds(foodtasticBrandOptions, unitRebate.foodtasticBrandIds)}`}</Text>
      {unitRebate.rebateTargetTypeId === ERebateTargetType.ALL_SALES &&
        <>
          <Text style={styles.sectionBody}>{t('supplierContractForm.summary.unitRebates.applicableTo.allSales.title')}</Text>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.allSalesRow1}>{allSalesHeaders[0]}</Text>
              <Text style={styles.allSalesRow2}>{allSalesHeaders[1]}</Text>
              <Text style={styles.allSalesRow3}>{allSalesHeaders[2]}</Text>
            </View>
            {unitRebate.rebateDataRows.map((row, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.allSalesRow1}>{getOptionNamesByIds(distributionCenterOptions, row.distributionCenterIds)}</Text>
                <Text style={styles.allSalesRow2}>{getOptionNameById(measurementTypeOptionsUnitRebate, row.rebateMeasurementTypeId ?? '')}</Text>
                <Text style={styles.allSalesRow3}>{row.amount}</Text>
              </View>
            ))}
          </View>
        </>
      }
      {unitRebate.rebateTargetTypeId === ERebateTargetType.PRODUCT_BRAND &&
        <>
          <Text style={styles.sectionBody}>{t('supplierContractForm.summary.unitRebates.applicableTo.brands.title')}</Text>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.brandsRow1}>{specificBrandsHeaders[0]}</Text>
              <Text style={styles.brandsRow2}>{specificBrandsHeaders[1]}</Text>
              <Text style={styles.brandsRow3}>{specificBrandsHeaders[2]}</Text>
              <Text style={styles.brandsRow4}>{specificBrandsHeaders[3]}</Text>
            </View>
            {unitRebate.rebateDataRows.map((row, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.brandsRow1}>{getOptionNamesByIds(distributionCenterOptions, row.distributionCenterIds)}</Text>
                <Text style={styles.brandsRow2}>{getOptionNameById(brandOptions, (row as IUnitRebateBrandRow).productBrandId ?? '')}</Text>
                <Text style={styles.brandsRow3}>{getOptionNameById(measurementTypeOptionsUnitRebate, row.rebateMeasurementTypeId ?? '')}</Text>
                <Text style={styles.brandsRow4}>{row.amount}</Text>
              </View>
            ))}
          </View>
        </>
      }
      {unitRebate.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT &&
        <>
          <Text style={styles.sectionBody}>{t('supplierContractForm.summary.unitRebates.applicableTo.products.title')}</Text>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.productRows1}>{specificProductsHeaders[0]}</Text>
              <Text style={styles.productRows2}>{specificProductsHeaders[1]}</Text>
              <Text style={styles.productRows3}>{specificProductsHeaders[2]}</Text>
              <Text style={styles.productRows4}>{specificProductsHeaders[3]}</Text>
              <Text style={styles.productRows5}>{specificProductsHeaders[4]}</Text>
              <Text style={styles.productRows6}>{specificProductsHeaders[5]}</Text>
            </View>
            {unitRebate.rebateDataRows.map((row, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.productRows1}>{getOptionNamesByIds(distributionCenterOptions, row.distributionCenterIds)}</Text>
                <Text style={styles.productRows2}>{(row as IUnitRebateProductRow).gtin}</Text>
                <Text style={styles.productRows3}>{(row as IUnitRebateProductRow).productCode}</Text>
                <Text style={styles.productRows4}>{(row as IUnitRebateProductRow).productDescriptionEN}</Text>
                <Text style={styles.productRows5}>{getOptionNameById(measurementTypeOptionsUnitRebate, row.rebateMeasurementTypeId ?? '')}</Text>
                <Text style={styles.productRows6}>{row.amount}</Text>
              </View>
            ))}
          </View>
        </>
      }
    </View>
  )
}
