import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { ERebateTargetType, type IOption, type IVolumeRebate } from '../../../../types'
import { useTranslation } from 'react-i18next'
import { getOptionNameById, getOptionNamesByIds } from '../../../../utils'

const styles = StyleSheet.create({
  table: {
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8
  },
  header: {
    borderTop: 'none'
  },
  bold: {
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 8,
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  sectionBody: {
    fontSize: 6,
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  },
  section: {
    fontSize: 6,
    marginBottom: 6,
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  },
  row1: {
    fontSize: 6,
    width: '25%'
  },
  row2: {
    fontSize: 6,
    width: '25%'
  },
  row3: {
    fontSize: 6,
    width: '25%'
  },
  row4: {
    fontSize: 6,
    width: '25%'
  }
})

interface IVolumeRebatesExportProps {
  brandOptions: IOption[]
  foodtasticBrandOptions: IOption[]
  measurementTypeOptionsVolumeRebate: IOption[]
  productOptions: IOption[]
  volumeRebate: IVolumeRebate
  index: number
}

export const VolumeRebatesExport: React.FC<IVolumeRebatesExportProps> = (props) => {
  const { t } = useTranslation()
  const {
    brandOptions,
    foodtasticBrandOptions,
    measurementTypeOptionsVolumeRebate,
    productOptions,
    volumeRebate,
    index
  } = props

  const headers: string[] = [
    t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.min'),
    t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.max'),
    t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.measurementUnit'),
    t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.rebateAmount')
  ]

  return (
    <View>
      <Text style={styles.subtitle}>{`${t('supplierContractForm.summary.volumeRebates.title')} ${index + 1}:`}</Text>
      <Text style={styles.sectionBody}>
        {`${t('supplierContractForm.summary.commons.applicableTo')} ${foodtasticBrandOptions.length === volumeRebate.foodtasticBrandIds.length ? t('supplierContractForm.commons.allFoodtasticBrands') : getOptionNamesByIds(foodtasticBrandOptions, volumeRebate.foodtasticBrandIds)}`}
      </Text>
      {volumeRebate.rebateTargetTypeId === ERebateTargetType.ALL_SALES &&
        <Text style={styles.sectionBody}>{t('supplierContractForm.summary.volumeRebates.applicableTo.allSales.title')}</Text>
      }
      {volumeRebate.rebateTargetTypeId === ERebateTargetType.PRODUCT_BRAND &&
        <>
          <Text style={styles.sectionBody}>{t('supplierContractForm.summary.volumeRebates.applicableTo.brands.title')}</Text>
          {volumeRebate.brandIds.map((id, index) => {
            return (
              <Text key={index} style={[styles.sectionBody, { marginBottom: 0 }]}>{`${getOptionNameById(brandOptions, id) ?? '-'}`}</Text>
            )
          })}
        </>
      }
      {volumeRebate.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT &&
        <>
          <Text style={styles.sectionBody}>{t('supplierContractForm.summary.volumeRebates.applicableTo.products.title')}</Text>
            {volumeRebate.productIds.map((id, index) => {
              return (
                <Text key={index} style={[styles.sectionBody, { marginBottom: 0 }]}>{`${getOptionNameById(productOptions, id) ?? '-'}`}</Text>
              )
            })}
        </>
      }
      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.header]}>
          <Text style={styles.row1}>{headers[0]}</Text>
          <Text style={styles.row2}>{headers[1]}</Text>
          <Text style={styles.row3}>{headers[2]}</Text>
          <Text style={styles.row4}>{headers[3]}</Text>
        </View>
        {volumeRebate.thresholds.map((threshold, i) => (
          <View key={i} style={styles.row} wrap={false}>
            <Text style={styles.row1}>{threshold.min}</Text>
            <Text style={styles.row2}>{threshold.max}</Text>
            <Text style={styles.row3}>{getOptionNameById(measurementTypeOptionsVolumeRebate, volumeRebate.rebateMeasurementTypeId ?? '')}</Text>
            <Text style={styles.row4}>{threshold.amount}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}
