import React, { type ReactNode, createContext, useContext, useReducer, useEffect } from 'react'
import { type IContractsListState, type IContractsListContext, type IRebateContractListItem, EContractsListActionType, type IContractListItem, type IOption } from '../types'
import { contractsListReducer } from '../reducers'
import { getContractList, getSuppliers } from '../components'
import { useTranslation } from 'react-i18next'
import { compareOptionNames } from '../utils'
import { useIsAuthenticated } from '@azure/msal-react'
import { initializeMsal } from '../components/api/authUtils'

const ContractsListFormInitialState: IContractsListState = {
  contracts: [],
  suppliers: [],
  loading: true
}

const defaultContextValue: IContractsListContext = {
  state: ContractsListFormInitialState,
  dispatch: (action) => {
    console.log('Dispatching action:', action)
  }
}

const ContractsListFormContext = createContext<IContractsListContext>(defaultContextValue)

interface IContractsListFormProviderProps {
  children: ReactNode
}

export const ContractsListProvider: React.FC<IContractsListFormProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(contractsListReducer, ContractsListFormInitialState)
  const { t } = useTranslation()

  const getSupplierOptions: () => Promise<IOption[]> = async () => {
    const suppliers = await getSuppliers()
    const options: IOption[] = suppliers.map((supplier) => {
      return {
        id: supplier.SupplierId,
        name: supplier.SupplierName
      }
    })

    options.sort(compareOptionNames)
    return options
  }
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    const fetchContractsList = async (): Promise<void> => {
      let contracts: IContractListItem[] = []
      let suppliers: IOption[] = []
      try {
        await initializeMsal()
        suppliers = await getSupplierOptions()
        const contractsList: IRebateContractListItem[] = await getContractList()
        contracts = contractsList?.map((contract) => ({
          id: contract.contractID,
          supplierId: contract.supplierId,
          supplierName: contract.supplierName,
          contractNo: contract.contractID,
          assignedTo: contract.LocalContact?.map((contact) => `${contact.firstName} ${contact.lastName}`).join(', '),
          lastModified: contract.LastModifiedDate,
          endDate: contract.contractEndDate,
          status: contract.status
            ? new Date(contract.contractEndDate) < new Date()
              ? t('contractsList.status.expired')
              : t('contractsList.status.active')
            : t('contractsList.status.draft')
        })) ?? []
      } catch (error) {
        console.error('Error fetching contract list:', error)
        suppliers = []
        contracts = []
      } finally {
        const setContractListData = {
          type: EContractsListActionType.SET_LIST_DATA,
          payload: {
            contracts,
            suppliers
          }
        }
        dispatch(setContractListData)
      }
    }
    if (isAuthenticated) {
      fetchContractsList().catch((error) => { console.error('Error during fetchData:', error) })
    }
  }, [isAuthenticated])

  return (
    <ContractsListFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ContractsListFormContext.Provider>
  )
}

export const useContractsList: () => IContractsListContext = () => {
  return useContext(ContractsListFormContext)
}
