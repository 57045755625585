import * as React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { login } from '../api/authUtils'
import { useTranslation } from 'react-i18next'

export const LoginBox: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'fit-content',
          width: 'fit-content',
          maxWidth: '300px',
          padding: '30px',
          paddingY: '40px',
          marginTop: '10%',
          marginBottom: '5%',
          borderRadius: '15px',
          backgroundColor: '#f0f0f0'
        }}
        >
        <Typography variant="h6" gutterBottom color="text.primary" sx={{ textAlign: 'center', fontWeight: 'bold' }}>{t('layout.vrms')}</Typography>
        <Button variant="contained" color='primary' sx={{ marginTop: '20px', borderRadius: '5px' }} onClick={
        () => {
          void (async () => {
            await login()
          })()
        }
        }>{t('layout.login')}</Button>
    </Box>
  )
}
