import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  type SelectChangeEvent,
  Chip
} from '@mui/material'
import { DataGrid, type GridRowsProp, type GridColDef, type GridSortModel } from '@mui/x-data-grid'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'
import { useContractsList } from '../../stores/ContractsList'
import { Content } from '../layout'
import { formatDate, selectMenuProps } from '../../utils'

export const SupplierContractsList: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedSupplier, setSelectedSupplier] = useState('')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useContractsList()
  const contracts = state.contracts
  const suppliers = state.suppliers
  const loadingList = state.loading

  const filteredContracts = contracts.filter((contract) =>
    contract.supplierName.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const rows: GridRowsProp = filteredContracts

  const columns: GridColDef[] = [
    { field: 'supplierName', headerName: t('contractsList.headers.supplierName'), flex: 1 },
    {
      field: 'contractNo',
      headerName: t('contractsList.headers.contractNo'),
      flex: 1,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Link to={`/form/${params.row.supplierId}/${params.row.id}`}>
          {params.value}
        </Link>
      )
    },
    { field: 'assignedTo', headerName: t('contractsList.headers.assignedTo'), flex: 1 },
    {
      field: 'lastModified',
      headerName: t('contractsList.headers.lastModified'),
      flex: 1,
      valueGetter: (params) => {
        return new Date(params.value as string)
      },
      renderCell: (params) => {
        const date = new Date(params.value as string)
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(date)
        return formattedDate
      }
    },
    {
      field: 'endDate',
      headerName: t('contractsList.headers.endDate'),
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        return new Date(params.value as string)
      },
      renderCell: (params) => {
        return formatDate(params.value as string)
      }
    },
    {
      field: 'status',
      headerName: t('contractsList.headers.status'),
      flex: 1,
      renderCell: (params) => {
        let chipColor = ''
        let textColor = ''
        switch (params.value) {
          case t('contractsList.status.draft'): // TODO: replace with enum here and in store
            chipColor = '#FAC710'
            textColor = '#FFFFFF'
            break
          case t('contractsList.status.active'):
            chipColor = '#8FD14F'
            textColor = '#FFFFFF'
            break
          case t('contractsList.status.expired'):
            chipColor = '#E6E6E6'
            break
          default:
            chipColor = '#E6E6E6'
            textColor = '#1A1A1A'
            break
        }

        return (
          <Chip label={params.value} style={{ backgroundColor: chipColor, color: textColor, borderRadius: '15px', fontWeight: 'bold' }} />
        )
      }
    }
  ]

  const handleOpenModal = (): void => {
    setModalOpen(true)
  }

  const handleCloseModal = (): void => {
    setModalOpen(false)
    setSelectedSupplier('')
  }

  const handleContinueToForm = (): void => {
    const selectedSupplierData = suppliers.find(
      (supplier) => supplier.id === selectedSupplier
    )
    if (selectedSupplierData !== undefined) {
      navigate(`/form/${selectedSupplier}`, {
        state: {
          supplierName: selectedSupplierData.name
        }
      })
    }
  }

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    setSelectedSupplier(event.target.value)
  }

  return (
    <Content
      header={
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {t('layout.titleList')}
        </Typography>
      }
      body={
        <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={handleOpenModal}>
                {t('contractsList.buttons.createNewContract')}
              </Button>
              <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth>
                <DialogTitle>{t('contractsList.modal.title')}</DialogTitle>
                <DialogContent>
                  <Typography>{t('contractsList.modal.selectSupplier')}</Typography>
                  <Select
                    value={selectedSupplier}
                    onChange={handleSelectChange}
                    fullWidth
                    MenuProps={selectMenuProps}
                  >
                    {suppliers.map((supplier) => (
                      <MenuItem key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" color="primary" onClick={handleCloseModal}>
                    {t('commons.buttons.cancel')}
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleContinueToForm}>
                    {t('commons.buttons.continue')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <TextField
                placeholder={t('contractsList.fields.search')}
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => { setSearchTerm(e.target.value) }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h5"
                variant="h5"
                gutterBottom
              >
                {t('contractsList.text.allContracts')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={rows}
                columns={columns}
                onSortModelChange={(model) => { setSortModel(model) }}
                sortModel={sortModel}
                loading={loadingList}
                sx={{
                  '& .MuiDataGrid-columnHeaders': {
                    background: '#F3CCCD'
                  }
                }}
              />
            </Grid>
          </Grid>
      }
    />
  )
}
