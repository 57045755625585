import React, { createContext, useContext, useReducer, useEffect, type ReactNode } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getContractById, getSuppliersById } from '../components'
import { contractFormErrorsInitialState, convertRebateContractToFormState } from '../utils'
import { supplierContractFormReducer } from '../reducers'
import {
  type ISupplierContractFormState,
  type ISupplierContractFormContext,
  type IRebateContract,
  ESupplierContractFormActionType
} from '../types'
import { useIsAuthenticated } from '@azure/msal-react'
import { initializeMsal } from '../components/api/authUtils'

const supplierContractFormInitialState: ISupplierContractFormState = {
  contractForm: {
    contractId: null,
    supplierId: null,
    supplierName: '',
    supplierContact: {
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      phoneExt: '',
      email: '',
      address: '',
      city: '',
      province: '',
      zipCode: ''
    },
    contact: {
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      phoneExt: '',
      email: ''
    },
    contractStartDate: '',
    contractEndDate: '',
    hasLumpSumRebate: false,
    hasContractedPrice: false,
    hasVolumeRebate: false,
    hasUnitRebate: false,
    contractedPrices: [],
    lumpSumRebates: [],
    volumeRebates: [],
    unitRebates: [],
    comments: '',
    attachments: '',
    signature: '',
    status: false,
    volumeRebatesPaymentFrequencyId: null,
    volumeRebatesFirstPaymentIssuedDate: '',
    unitRebatesPaymentFrequencyId: null,
    unitRebatesFirstPaymentIssuedDate: ''
  },
  contractFormErrors: contractFormErrorsInitialState,
  loading: true,
  errorLoadingState: false
}

const defaultContextValue: ISupplierContractFormContext = {
  state: supplierContractFormInitialState,
  dispatch: (action) => {
    console.log('Dispatching action:', action)
  }
}

const SupplierContractFormContext = createContext<ISupplierContractFormContext>(defaultContextValue)

interface ISupplierContractFormProviderProps {
  children: ReactNode
}

export const SupplierContractFormProvider: React.FC<ISupplierContractFormProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(supplierContractFormReducer, supplierContractFormInitialState)
  const { contractId, supplierId } = useParams()
  const location = useLocation()

  const getSupplierName = async (supplierId: string | undefined): Promise<string> => {
    if (supplierId === undefined) {
      return ''
    }
    const supplier = await getSuppliersById(supplierId)
    return supplier?.[0]?.SupplierName ?? ''
  }

  const fetchContract = async (contractId: string): Promise<void> => {
    try {
      const contracts: IRebateContract[] = await getContractById(contractId)
      const contract = contracts?.[0] ?? null
      if (contract !== null) {
        const loadState = {
          type: ESupplierContractFormActionType.LOAD_CONTRACT,
          payload: convertRebateContractToFormState(contract)
        }
        dispatch(loadState)
      } else {
        const setError = {
          type: ESupplierContractFormActionType.SET_LOADING_ERROR,
          payload: {
            errorLoadingState: true
          }
        }
        dispatch(setError)
      }
    } catch (error) {
      console.error('Error fetching contract list:', error)
      const setError = {
        type: ESupplierContractFormActionType.SET_LOADING_ERROR,
        payload: {
          errorLoadingState: true
        }
      }
      dispatch(setError)
    }
  }

  const fetchData = async (): Promise<void> => {
    try {
      await initializeMsal()
      if (contractId !== undefined) {
        await fetchContract(contractId)
      } else {
        let supplierName = location.state?.supplierName
        if (supplierName === undefined) {
          supplierName = await getSupplierName(supplierId).catch((error) => {
            console.error('Error during fetchData:', error)
            return ''
          })
        }
        const loadState = {
          type: ESupplierContractFormActionType.LOAD_CONTRACT,
          payload: {
            ...supplierContractFormInitialState.contractForm,
            contractId,
            supplierId,
            supplierName
          }
        }
        dispatch(loadState)
      }
    } catch (error) {
      console.error('Error during fetchData:', error)
    }
  }

  const isAuthenticated = useIsAuthenticated()
  useEffect(() => {
    if (isAuthenticated) {
      fetchData().catch((error) => { console.error('Error during fetchData:', error) })
    }
  }, [contractId, supplierId, location.state?.supplierName, isAuthenticated])

  return (
    <SupplierContractFormContext.Provider value={{ state, dispatch }}>
      {children}
    </SupplierContractFormContext.Provider>
  )
}

export const useSupplierForm: () => ISupplierContractFormContext = () => {
  return useContext(SupplierContractFormContext)
}
