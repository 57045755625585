import type { IUnitRebate, ILumpSumRebate, IVolumeRebate, IRebateContract, SupplierContact, LocalContact, IContractedPrice, EPaymentFrequency, EMeasurementType, ERebateTargetType, IContractForm } from '../types'
import { v4 as uuid } from 'uuid'
import { formatDate, isEmpty } from './helpers'

export const convertRebateContractToFormState = (contract: IRebateContract): IContractForm => {
  const {
    SupplierContact,
    LocalContact,
    contractID,
    contractStartDate,
    contractEndDate,
    hasLumpSumRebate,
    hasContractedPrice,
    hasVolumeRebate,
    hasUnitRebate,
    ContractPriceRebate,
    LumpSumRebate,
    VolumeRebate,
    UnitRebate,
    comments,
    attachment,
    status,
    signature,
    ContractBrandId,
    supplierId,
    supplierName
  } = contract

  const supplierContact: SupplierContact = SupplierContact[0]
  const localContact: LocalContact = LocalContact[0]

  const targetContractPrices: IContractedPrice[] = ContractBrandId.map((brand) => ({
    id: uuid(),
    foodtasticBrandIds: brand.foodtasticBrandIds,
    contractedPriceData: ContractPriceRebate !== null && ContractPriceRebate.length > 0
      ? ContractPriceRebate
        .filter((line) => line.lineId === brand.lineid)
        .map((line) => ({
          id: uuid(),
          productGTIN: line.GTIN,
          productCode: line.supplierProductCode,
          productDescriptionEN: line.productDescriptionEN,
          minimumVolume: line.minimumVolume,
          contractedPrice: line.contractedPrice,
          distributionCenterIds: line.distributorCenters,
          dateInEffect: formatDate(line.dateInEffect),
          expirationDate: formatDate(line.expirationDate),
          deliveryMethodId: line.deliveryMethod
        }))
      : []
  }))

  const targetLumpSumRebates: ILumpSumRebate[] = LumpSumRebate !== null && LumpSumRebate.length > 0
    ? LumpSumRebate.map((rebate) => ({
      id: uuid(),
      lumpSumAmount: rebate.lumpSumAmount ?? null,
      paymentFrequencyId: rebate.paymentFrequency as EPaymentFrequency,
      firstPaymentIssuedDate: formatDate(rebate.firstPaymentDate),
      firstPaymentAmount: rebate.firstPaymentAmount ?? null
    }))
    : []

  const targetVolumeRebates: IVolumeRebate[] = VolumeRebate !== null && VolumeRebate.length > 0
    ? VolumeRebate.map((rebate) => ({
      id: uuid(),
      foodtasticBrandIds: rebate.foodTasticBrands,
      brandIds: rebate.supplierBrands,
      productIds: rebate.productIDs,
      rebateTargetTypeId: rebate.rebateTypeId !== null ? rebate.rebateTypeId.toString() as ERebateTargetType : null,
      rebateMeasurementTypeId: rebate.rebateMeasurementTypeId !== null ? rebate.rebateMeasurementTypeId.toString() as EMeasurementType : null,
      thresholds: rebate.thresholds.map((threshold) => ({
        id: uuid(),
        amount: threshold.amount,
        min: threshold.min,
        max: threshold.max
      }))
    }))
    : []

  const targetUnitRebates: IUnitRebate[] = UnitRebate !== null && UnitRebate.length > 0
    ? UnitRebate.map((rebate) => ({
      id: uuid(),
      foodtasticBrandIds: rebate.foodTasticBrands,
      rebateTargetTypeId: rebate.rebateTypeId.toString() as ERebateTargetType,
      rebateDataRows: rebate.rebateDataRows.map(row => { return { ...row, id: uuid() } })
    }))
    : []

  const formValues: IContractForm = {
    contractId: contractID ?? null,
    supplierContact: {
      firstName: !isEmpty(supplierContact.firstName) ? supplierContact.firstName : '',
      middleName: !isEmpty(supplierContact.middleName) ? supplierContact.middleName : '',
      lastName: !isEmpty(supplierContact.lastName) ? supplierContact.lastName : '',
      phone: !isEmpty(supplierContact.phoneNumber) ? supplierContact.phoneNumber : '',
      phoneExt: !isEmpty(supplierContact.phoneExtension) ? supplierContact.phoneExtension : '',
      email: !isEmpty(supplierContact.emailAddress) ? supplierContact.emailAddress : '',
      address: !isEmpty(supplierContact.addressLine) ? supplierContact.addressLine : '',
      city: !isEmpty(supplierContact.city) ? supplierContact.city : '',
      province: !isEmpty(supplierContact.provinceState) ? supplierContact.provinceState : '',
      zipCode: !isEmpty(supplierContact.zipCode) ? supplierContact.zipCode : ''
    },
    contact: {
      firstName: !isEmpty(localContact.firstName) ? localContact.firstName : '',
      middleName: !isEmpty(localContact.middleName) ? localContact.middleName : '',
      lastName: !isEmpty(localContact.lastName) ? localContact.lastName : '',
      phone: !isEmpty(localContact.phoneNumber) ? localContact.phoneNumber : '',
      phoneExt: !isEmpty(localContact.phoneExtension) ? localContact.phoneExtension : '',
      email: !isEmpty(localContact.emailAddress) ? localContact.emailAddress : ''
    },
    contractStartDate: formatDate(contractStartDate),
    contractEndDate: formatDate(contractEndDate),
    hasLumpSumRebate: hasLumpSumRebate || false,
    hasContractedPrice: hasContractedPrice || false,
    hasVolumeRebate: hasVolumeRebate || false,
    hasUnitRebate: hasUnitRebate || false,
    status: status || false,
    comments: !isEmpty(comments) ? comments : '',
    attachments: !isEmpty(attachment) ? attachment : '',
    signature: !isEmpty(signature) ? signature : '',
    contractedPrices: targetContractPrices,
    lumpSumRebates: targetLumpSumRebates,
    volumeRebates: targetVolumeRebates,
    volumeRebatesPaymentFrequencyId: VolumeRebate?.[0]?.paymentFrequencyId?.toString() as EPaymentFrequency ?? null,
    volumeRebatesFirstPaymentIssuedDate: formatDate(VolumeRebate?.[0]?.firstPaymentIssuedDate),
    unitRebates: targetUnitRebates,
    unitRebatesPaymentFrequencyId: UnitRebate?.[0]?.paymentFrequencyId?.toString() as EPaymentFrequency ?? null,
    unitRebatesFirstPaymentIssuedDate: formatDate(UnitRebate?.[0]?.firstPaymentIssuedDate),
    supplierId: supplierId !== null ? supplierId.toString() : null,
    supplierName
  }

  return formValues
}
