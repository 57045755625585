import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../../stores/SupplierContractForm'
import { getOptionNameById, getOptionNamesByIds } from '../../../../utils'
import { ERebateTargetType, type IVolumeRebate } from '../../../../types'
import { type IHeader, SimpleTable } from '../../../commons'
import { useOptions } from '../../../../stores/Options'

export const VolumeRebatesSummary: React.FC = () => {
  const { t } = useTranslation()
  const { state } = useSupplierForm()
  const { state: options } = useOptions()
  const contractForm = state.contractForm
  const rebates = contractForm.volumeRebates
  const paymentFrequencyId = contractForm.volumeRebatesPaymentFrequencyId
  const firstPaymentDate = contractForm.volumeRebatesFirstPaymentIssuedDate
  const hasEntries = rebates.length > 0

  const headers: IHeader[] = [
    {
      key: 'thresholdMin',
      displayValue: t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.min')
    },
    {
      key: 'thresholdMax',
      displayValue: t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.max')
    },
    {
      key: 'measurementUnit',
      displayValue: t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.measurementUnit')
    },
    {
      key: 'rebateAmount',
      displayValue: t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.rebateAmount')
    }
  ]

  const getThresholdRows: (entry: IVolumeRebate) => Array<Record<string, React.ReactNode>> = (entry) => {
    const thresholds = [...entry.thresholds]
    const thresholdsArray = Array.isArray(thresholds) ? thresholds : []
    return thresholdsArray.map((threshold) => {
      return {
        rebateAmount: threshold.amount,
        thresholdMin: threshold.min,
        thresholdMax: threshold.max,
        measurementUnit: getOptionNameById(options.measurementTypeOptionsVolumeRebate, entry.rebateMeasurementTypeId ?? '')
      }
    })
  }

  const renderProductsOrBrands: (rebate: IVolumeRebate) => React.ReactElement | null = (rebate) => {
    const type = rebate.rebateTargetTypeId
    switch (type) {
      case ERebateTargetType.ALL_SALES:
        return (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('supplierContractForm.summary.volumeRebates.applicableTo.allSales.title')}</Typography>
            </Grid>
          </>
        )
      case ERebateTargetType.PRODUCT_BRAND:
        return (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('supplierContractForm.summary.volumeRebates.applicableTo.brands.title')}</Typography>
              {rebate.brandIds.map((id) => {
                return (
                  <Typography key={`brand-${id}`} variant="body1">{`${getOptionNameById(options.brandOptions, id) ?? '-'}`}</Typography>
                )
              })}
            </Grid>
          </>
        )
      case ERebateTargetType.SPECIFIC_PRODUCT:
        return (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('supplierContractForm.summary.volumeRebates.applicableTo.products.title')}</Typography>
              {rebate.productIds.map((id) => {
                return (
                  <Typography key={`product-${id}`} variant="body1">{`${getOptionNameById(options.productOptions, id) ?? '-'}`}</Typography>
                )
              })}
            </Grid>
          </>
        )
      default:
        return null
    }
  }

  return (
    hasEntries
      ? <Grid container spacing={2}>
        {rebates.map((entry, index) => (
          <>
            <Grid item xs={12}>
              <Typography fontWeight="bold" variant="subtitle1">{`${t('supplierContractForm.summary.volumeRebates.subtitle')} ${index + 1}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                {`${t('supplierContractForm.summary.commons.applicableTo')} ${options.foodtasticBrandOptions.length === entry.foodtasticBrandIds.length ? t('supplierContractForm.commons.allFoodtasticBrands') : getOptionNamesByIds(options.foodtasticBrandOptions, entry.foodtasticBrandIds)}`}
              </Typography>
            </Grid>
            {renderProductsOrBrands(entry)}
            <Grid item xs={12}>
              {entry.thresholds.length > 0 &&
                <>
                  <Typography variant="subtitle1">{t('supplierContractForm.summary.volumeRebates.thresholds.title')}</Typography>
                  <SimpleTable
                    headers={headers}
                    rows={getThresholdRows(entry)}
                  />
                </>
              }
            </Grid>
          </>
        ))}
        <Grid item xs={12}>
          <Typography fontWeight="bold" variant="subtitle1">{t('supplierContractForm.summary.volumeRebates.paymentConditions.title')}</Typography>
          <Typography variant="subtitle1">{`${t('supplierContractForm.summary.volumeRebates.paymentConditions.payment')}: ${getOptionNameById(options.paymentFrequencyOptions, paymentFrequencyId ?? '') ?? '-'}`}</Typography>
          <Typography variant="subtitle1">{`${t('supplierContractForm.summary.volumeRebates.paymentConditions.firstPaymentDate')}: ${firstPaymentDate ?? '-'}`}</Typography>
        </Grid>
      </Grid>
      : null
  )
}
