import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import type { ILumpSumRebate, IOption } from '../../../../types'
import { getOptionNameById } from '../../../../utils'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  table: {
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8
  },
  header: {
    borderTop: 'none'
  },
  bold: {
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 8,
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  row1: {
    fontSize: 6,
    width: '25%'
  },
  row2: {
    fontSize: 6,
    width: '25%'
  },
  row3: {
    fontSize: 6,
    width: '25%'
  },
  row4: {
    fontSize: 6,
    width: '25%'
  }
})

interface ILumpSumExportProps {
  index: number
  lumpSumRebate: ILumpSumRebate
  paymentFrequencyOptions: IOption[]
}

export const LumpSumExport: React.FC<ILumpSumExportProps> = (props) => {
  const { t } = useTranslation()
  const { lumpSumRebate, index, paymentFrequencyOptions } = props

  const headers: string[] = [
    t('supplierContractForm.summary.lumpSum.subtitle.amount'),
    t('supplierContractForm.summary.lumpSum.subtitle.payment'),
    t('supplierContractForm.summary.lumpSum.subtitle.firstPaymentDate'),
    t('supplierContractForm.summary.lumpSum.subtitle.firstPaymentAmount')
  ]

  return (
    <View>
      <Text style={styles.subtitle}>{`${t('supplierContractForm.summary.lumpSum.title')} ${index + 1}:`}</Text>
      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.header]}>
          <Text style={styles.row1}>{headers[0]}</Text>
          <Text style={styles.row2}>{headers[1]}</Text>
          <Text style={styles.row3}>{headers[2]}</Text>
          <Text style={styles.row4}>{headers[3]}</Text>
        </View>
          <View style={styles.row} wrap={false}>
              <Text style={styles.row1}>{lumpSumRebate.lumpSumAmount}</Text>
              <Text style={styles.row2}>{getOptionNameById(paymentFrequencyOptions, lumpSumRebate.paymentFrequencyId ?? '')}</Text>
              <Text style={styles.row3}>{lumpSumRebate.firstPaymentIssuedDate}</Text>
              <Text style={styles.row4}>{lumpSumRebate.firstPaymentAmount}</Text>
          </View>
      </View>
    </View>
  )
}
