import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import type { IOption, IProductDataRow } from '../../../../types'
import { getOptionNamesByIds } from '../../../../utils'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  table: {
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8
  },
  header: {
    borderTop: 'none'
  },
  subtitle: {
    fontSize: 8,
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  sectionBody: {
    fontSize: 6,
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  },
  row1: {
    fontSize: 6,
    width: '12%',
    fontWeight: 'normal'
  },
  row2: {
    fontSize: 6,
    width: '12%'
  },
  row3: {
    fontSize: 6,
    width: '12%'
  },
  row4: {
    fontSize: 6,
    width: '10%'
  },
  row5: {
    fontSize: 6,
    width: '10%'
  },
  row6: {
    fontSize: 6,
    width: '12%'
  },
  row7: {
    fontSize: 6,
    width: '10%'
  },
  row8: {
    fontSize: 6,
    width: '10%'
  },
  row9: {
    fontSize: 6,
    width: '12%'
  }
})

interface IContractedPricesExportProps {
  contractedPrices: IProductDataRow[]
  deliveryMethodOptions: IOption[]
  distributionCenterOptions: IOption[]
  foodtasticBrandOptions: IOption[]
  foodtasticBrandIds: string[]
  index: number
}

export const ContractedPricesExport: React.FC<IContractedPricesExportProps> = (props) => {
  const { t } = useTranslation()
  const {
    contractedPrices,
    deliveryMethodOptions,
    distributionCenterOptions,
    foodtasticBrandOptions,
    index,
    foodtasticBrandIds
  } = props

  const headers: string[] = [
    t('supplierContractForm.contractedPrices.section.headers.productGTIN'),
    t('supplierContractForm.contractedPrices.section.headers.productCode'),
    t('supplierContractForm.contractedPrices.section.headers.productDescription'),
    t('supplierContractForm.contractedPrices.section.headers.minimumVolume'),
    t('supplierContractForm.contractedPrices.section.headers.contractedPrice'),
    t('supplierContractForm.contractedPrices.section.headers.distributorCenters'),
    t('supplierContractForm.contractedPrices.section.headers.startDate'),
    t('supplierContractForm.contractedPrices.section.headers.expirationDate'),
    t('supplierContractForm.contractedPrices.section.headers.deliveryMethod')
  ]

  const rowsData = contractedPrices.filter((row) => row.contractedPrice !== null).map((row) => ({
    productGTIN: row.productGTIN,
    productCode: row.productCode,
    productDescription: row.productDescriptionEN,
    minimumVolume: row.minimumVolume,
    contractedPrice: row.contractedPrice,
    distributorCenters: getOptionNamesByIds(distributionCenterOptions, row.distributionCenterIds),
    startDate: row.dateInEffect,
    expirationDate: row.expirationDate,
    deliveryMethod: getOptionNamesByIds(deliveryMethodOptions, row.deliveryMethodId !== null ? [row.deliveryMethodId] : [])
  }))

  return (
    <View>
      <Text style={styles.subtitle}>{`${t('supplierContractForm.summary.contractedPrices.title')} ${index + 1}:`}</Text>
      <Text style={styles.sectionBody}>
        {`${t('supplierContractForm.summary.commons.applicableTo')} ${foodtasticBrandOptions.length === foodtasticBrandIds.length ? t('supplierContractForm.commons.allFoodtasticBrands') : getOptionNamesByIds(foodtasticBrandOptions, foodtasticBrandIds)}`}
      </Text>
      <View style={styles.table}>
        <View style={[styles.row, styles.header]}>
          <Text style={styles.row1}>{headers[0]}</Text>
          <Text style={styles.row2}>{headers[1]}</Text>
          <Text style={styles.row3}>{headers[2]}</Text>
          <Text style={styles.row4}>{headers[3]}</Text>
          <Text style={styles.row5}>{headers[4]}</Text>
          <Text style={styles.row6}>{headers[5]}</Text>
          <Text style={styles.row7}>{headers[6]}</Text>
          <Text style={styles.row8}>{headers[7]}</Text>
          <Text style={styles.row9}>{headers[8]}</Text>
        </View>
        {rowsData.map((row, i) => (
          <View key={i} style={styles.row} wrap={false}>
            <Text style={styles.row1}>{row.productGTIN}</Text>
            <Text style={styles.row2}>{row.productCode}</Text>
            <Text style={styles.row3}>{row.productDescription}</Text>
            <Text style={styles.row4}>{row.minimumVolume}</Text>
            <Text style={styles.row5}>{row.contractedPrice}</Text>
            <Text style={styles.row6}>{row.distributorCenters}</Text>
            <Text style={styles.row7}>{row.startDate}</Text>
            <Text style={styles.row8}>{row.expirationDate}</Text>
            <Text style={styles.row9}>{row.deliveryMethod}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}
