import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, FormGroup, Grid, type SxProps, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../stores/SupplierContractForm'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ContactInformationSummary } from './partials/ContactInformationSummary'
import { ContractedPricesSummary } from './partials/ContractedPricesSummary'
import { type ISummary, type ISupplierContractFormAction, ESupplierContractFormActionType } from '../../../types'
import { LumpSumSummary } from './partials/LumpSumSummary'
import { VolumeRebatesSummary } from './partials/VolumeRebatesSummary'
import { UnitRebatesSummary } from './partials/UnitRebatesSummary'
import { widgetProps } from '../../../utils'
import { CustomTextField as TextField } from '../../commons'

const BACKGROUND_PALE_RED = '#FFFCFC'
const BACKGROUND_RED = '#CD5C63'
const WHITE = 'white'

const summary: SxProps = {
  backgroundColor: BACKGROUND_RED,
  color: WHITE
}

const content: SxProps = {
  backgroundColor: BACKGROUND_PALE_RED
}

interface ISummaryProps {
  isFormValid?: boolean
  changeFormIsValid: (newValue: boolean) => void
}

export const Summary: React.FC<ISummaryProps> = ({ isFormValid, changeFormIsValid }) => {
  const { state, dispatch } = useSupplierForm()
  const { t } = useTranslation()
  const contractForm = state.contractForm

  const handleCommentsChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newComments: string = event.target.value
    save({ ...contractForm, comments: newComments })
  }

  // const handleAttachmentsChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
  //   const newAttachments: string = event.target.value
  //   save({ ...contractForm, attachments: newAttachments })
  // }

  const handleFormIsValidChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    changeFormIsValid(event.target.checked)
  }

  const handleSignatureChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSignature: string = event.target.value
    save({ ...contractForm, signature: newSignature })
  }

  const save: (payload: ISummary) => void = (payload) => {
    const action: ISupplierContractFormAction = {
      type: ESupplierContractFormActionType.UPDATE_SUMMARY_DATA,
      payload
    }
    dispatch(action)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion sx={ content }>
            <AccordionSummary sx={ summary } expandIcon={<ExpandMoreIcon sx={{ color: WHITE }}/>}>
              <Typography variant="h5" gutterBottom>{`${t('supplierContractForm.summary.contractInformation.title')}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ContactInformationSummary/>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!contractForm.hasContractedPrice} sx={ content }>
            <AccordionSummary sx={ summary } expandIcon={<ExpandMoreIcon sx={{ color: WHITE }}/>}>
              <Typography variant="h5" gutterBottom>{`${t('supplierContractForm.summary.contractedPrices.title')}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ContractedPricesSummary/>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!contractForm.hasLumpSumRebate} sx={ content }>
            <AccordionSummary sx={ summary } expandIcon={<ExpandMoreIcon sx={{ color: WHITE }}/>}>
              <Typography variant="h5" gutterBottom>{`${t('supplierContractForm.summary.lumpSum.title')}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LumpSumSummary/>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!contractForm.hasVolumeRebate} sx={ content }>
            <AccordionSummary sx={ summary } expandIcon={<ExpandMoreIcon sx={{ color: WHITE }}/>}>
              <Typography variant="h5" gutterBottom>{`${t('supplierContractForm.summary.volumeRebates.title')}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <VolumeRebatesSummary/>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!contractForm.hasUnitRebate} sx={ content }>
            <AccordionSummary sx={ summary } expandIcon={<ExpandMoreIcon sx={{ color: WHITE }}/>}>
              <Typography variant="h5" gutterBottom>{`${t('supplierContractForm.summary.unitRebates.title')}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UnitRebatesSummary/>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Box
        sx={widgetProps}
        padding={2}
        marginTop={4}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
                variant="h5"
                gutterBottom
              >
                {t('supplierContractForm.summary.additionalInfo.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('supplierContractForm.summary.additionalInfo.comment.label')}
              placeholder={t('supplierContractForm.summary.additionalInfo.comment.placeholder')}
              id="add-comments"
              value={contractForm.comments}
              onChange={handleCommentsChange}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              label={t('supplierContractForm.summary.additionalInfo.attachment.label')}
              placeholder={t('supplierContractForm.summary.additionalInfo.attachment.placeholder')}
              id="add-attachments"
              value={contractForm.attachments}
              onChange={handleAttachmentsChange}
            />
          </Grid> */}
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                  label={
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>
                        {t('supplierContractForm.summary.additionalInfo.checkbox')}
                      </Typography>
                    </Box>
                  }
                  control={<Checkbox checked={isFormValid} onChange={handleFormIsValidChange} />}
                />
            </FormGroup>
          </Grid>
          <Grid item xs={8}/>
          <Grid item xs={4}>
            <TextField
              label={t('supplierContractForm.summary.additionalInfo.signature.label')}
              id="signature"
              value={contractForm.signature}
              onChange={handleSignatureChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
