import React, { useState } from 'react'
import { ContractInformation, ContractedPrices, LumpSum, Summary, UnitRebates, VolumeRebates } from './components'
import { Alert, Box, Button, Snackbar, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { submitForm } from '../api'
import { convertFormStateToContractPayload, validateForm } from '../../utils'
import { useSupplierForm } from '../../stores/SupplierContractForm'
import { useNavigate } from 'react-router-dom'
import { Content } from '../layout'
import { DownloadPDF } from './components/partials/DownloadPDF'

export const SupplierContractForm: React.FC = () => {
  const [step, setStep] = useState(1)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const { state, dispatch } = useSupplierForm()
  const [contractInformationTabError, setContractInformationTabError] = useState<boolean>(false)
  const [contractedPricesTabError, setContractedPricesTabError] = useState<boolean>(false)
  const [lumpSumTabError, setLumpSumTabError] = useState<boolean>(false)
  const [volumeRebatesTabError, setVolumeRebatesTabError] = useState<boolean>(false)
  const [unitRebatesTabError, setUnitRebatesTabError] = useState<boolean>(false)
  const [summaryTabError, setSummaryTabError] = useState<boolean>(false)
  const [formIsValid, setFormIsValid] = useState<boolean>(false)
  const contractForm = state.contractForm
  const supplierName = contractForm.supplierName

  const handleNext: () => void = () => {
    setStep(step + 1)
  }

  const handlePrev: () => void = () => {
    setStep(step - 1)
  }

  const handleTabChange: (event: React.SyntheticEvent, newValue: number) => void = (event, newValue) => {
    setStep(newValue)
  }

  const handleNavigateBackToList = (): void => {
    navigate('/')
  }

  const handleSnackbarClose = (): void => {
    setSnackbarOpen(false)
  }

  const handleFormIsValidChange: (newValue: boolean) => void = (newValue) => {
    setFormIsValid(newValue)
  }

  const handleSubmit: (status: boolean) => void = (status) => {
    const { isValid, tabErrors } = validateForm(dispatch, !status, { ...contractForm })
    if (isValid) {
      const payload = convertFormStateToContractPayload(contractForm, status)
      submitForm(payload)
        .then(handleNavigateBackToList)
        .catch(() => {
          setSnackbarMessage(t('commons.errors.submitContractForm'))
          setSnackbarOpen(true)
        })
    } else {
      setContractInformationTabError(tabErrors.contractInformationTabError)
      setContractedPricesTabError(tabErrors.contractedPricesTabError)
      setLumpSumTabError(tabErrors.lumpSumTabError)
      setVolumeRebatesTabError(tabErrors.volumeRebatesTabError)
      setUnitRebatesTabError(tabErrors.unitRebatesTabError)
      setSummaryTabError(tabErrors.summaryTabError)
      setSnackbarOpen(true)
      setSnackbarMessage(t('commons.errors.submitValidation'))
    }
  }

  const renderStep: () => React.ReactElement | null = () => {
    switch (step) {
      case 1:
        return <ContractInformation/>
      case 2:
        return <ContractedPrices/>
      case 3:
        return <LumpSum/>
      case 4:
        return <VolumeRebates/>
      case 5:
        return <UnitRebates/>
      case 6:
        return <Summary isFormValid={formIsValid} changeFormIsValid={handleFormIsValidChange}/>
      default:
        return null
    }
  }

  const renderButtons: () => React.ReactElement | null = () => {
    switch (step) {
      case 1:
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            gap={1}
          >
            <Button variant="contained" color="primary" onClick={() => { handleSubmit(false) }}>
              {t('commons.buttons.save')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              {t('commons.buttons.next')}
            </Button>
          </Box>
        )
      case 2:
      case 3:
      case 4:
      case 5:
        return (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Button variant="outlined" color="primary" onClick={handlePrev}>
              {t('commons.buttons.previous')}
            </Button>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              gap={1}
            >
              <Button variant="contained" color="primary" onClick={() => { handleSubmit(false) }}>
                {t('commons.buttons.save')}
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {t('commons.buttons.next')}
              </Button>
            </Box>
          </Box>
        )
      case 6:
        return (
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Button variant="outlined" color="primary" onClick={handlePrev}>
              {t('commons.buttons.previous')}
            </Button>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              gap={1}
            >
              <DownloadPDF isDistributorExport={true}/>
              <DownloadPDF isDistributorExport={false}/>
              <Button variant="contained" disabled={!formIsValid} color="primary" onClick={() => { handleSubmit(true) }}>
                {t('commons.buttons.submit')}
              </Button>
            </Box>
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Content
      header={
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {`${supplierName} - ${t('layout.titleForm')}`}
        </Typography>
      }
      body={
        <>
          <Box marginBottom={4}>
            <Tabs
              value={step}
              onChange={handleTabChange}
              aria-label="tabs"
            >
              <Tab
                value={1}
                label={t('supplierContractForm.tabs.contractInformation')}
                style={{
                  color: contractInformationTabError ? '#d3302f' : 'inherit'
                }}
              />
              <Tab
                value={2}
                label={t('supplierContractForm.tabs.contractedPrices')}
                style={{
                  color: contractedPricesTabError ? '#d3302f' : 'inherit'
                }}
              />
              <Tab
                value={3}
                label={t('supplierContractForm.tabs.lumpSum')}
                style={{
                  color: lumpSumTabError ? '#d3302f' : 'inherit'
                }}
              />
              <Tab
                value={4}
                label={t('supplierContractForm.tabs.volumeRebates')}
                style={{
                  color: volumeRebatesTabError ? '#d3302f' : 'inherit'
                }}
              />
              <Tab
                value={5}
                label={t('supplierContractForm.tabs.unitRebates')}
                style={{
                  color: unitRebatesTabError ? '#d3302f' : 'inherit'
                }}
              />
              <Tab
                value={6}
                label={t('supplierContractForm.tabs.summary')}
                style={{
                  color: summaryTabError ? '#d3302f' : 'inherit'
                }}
              />
            </Tabs>
          </Box>
          <Box>
            {renderStep()}
            <Snackbar
              open={snackbarOpen}
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
            >
              <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
        </>
      }
      footer={renderButtons()}
    />
  )
}
