import { Checkbox, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Tooltip, type SelectChangeEvent } from '@mui/material'
import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { type IOption } from '../../types'
import { useTranslation } from 'react-i18next'
import { selectMenuProps } from '../../utils'

interface IMultiSelectProps {
  id?: string
  label?: string
  items: IOption []
  value: string[]
  error?: boolean
  helperText?: string
  required?: boolean
  onChange: (newValues: string[]) => void
}

type IIdToNameMap = Record<string, string>

export const MultiSelect: React.FC<IMultiSelectProps> = ({
  id,
  label,
  items,
  value,
  error = false,
  helperText = '',
  required = false,
  onChange
}) => {
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>(value)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { t } = useTranslation()

  const idToNameMap: IIdToNameMap = items.reduce<IIdToNameMap>((acc, item) => {
    acc[item.id] = item.name
    return acc
  }, {})

  const allIds = items.map((item) => item.id)

  const handleSelectChange: (event: SelectChangeEvent<typeof selectedItemsIds>) => void = (event) => {
    const value = event.target.value
    const newValue = typeof value === 'string' ? value.split(',') : value
    if (value[value.length - 1] === 'all') {
      setSelectedItemsIds(selectedItemsIds.length === items.length ? [] : allIds)
      onChange(allIds)
      return
    }
    setSelectedItemsIds(newValue)
    onChange(newValue)
  }

  const allOptionsSelected = items.length > 0 && selectedItemsIds.length === items.length

  const tooltipContent = allOptionsSelected
    ? t('commons.allOptionsSelected')
    : (
        <ul>
          {selectedItemsIds.map((id) => (
            <li key={id}>{idToNameMap[id] ?? ''}</li>
          ))}
        </ul>
      )

  return (
    <>
      {label !== undefined && <InputLabel shrink={false} required={required} sx={{ mb: 1 }} id="multiple-checkbox-label">{label}</InputLabel>}
      <Tooltip title={tooltipContent} open={tooltipOpen && selectedItemsIds.length > 0}>
        <Select
          fullWidth
          labelId={label !== null ? 'multiple-checkbox-label' : undefined}
          id={id ?? uuid()}
          multiple
          value={selectedItemsIds}
          onMouseEnter={() => { setTooltipOpen(true) }}
          onMouseLeave={() => { setTooltipOpen(false) }}
          onMouseDown={() => { setTooltipOpen(false) }}
          onChange={handleSelectChange}
          input={<OutlinedInput/>}
          renderValue={(selected) => {
            if (allOptionsSelected) {
              return t('commons.allOptionsSelected')
            }
            return selected.map((id) => idToNameMap[id] ?? '').join(', ')
          }}
          MenuProps={selectMenuProps}
          error={error}
          aria-describedby={error ? 'helper-text' : ''}
          sx={{ background: 'white' }}
        >
          {items.length > 0 &&
            <MenuItem value="all">
              <Checkbox
                checked={allOptionsSelected}
              />
              <ListItemText primary="Select All" />
            </MenuItem>
          }
          {items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={selectedItemsIds.includes(item.id)} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
      {error && <FormHelperText error={true} id='helper-text'>{helperText}</FormHelperText>}
    </>
  )
}
