import React from 'react'
import { Document, Page, View, Text, Font, StyleSheet } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import type { EPaymentFrequency, ILumpSumRebate, IContractedPrice, IVolumeRebate, IUnitRebate, IOption, IProductDataRow } from '../../../../types'
import { ContractedPricesExport } from './ContractedPricesExport'
import { LumpSumExport } from './LumpSumExport'
import { VolumeRebatesExport } from './VolumeRebatesExport'
import { UnitRebatesExport } from './UnitRebatesExport'
import { getOptionNameById } from '../../../../utils'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf'
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400
    }
  ]
})

const styles = StyleSheet.create({
  page: {
    padding: 20
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 10,
    fontWeight: 'normal'
  },
  column: {
    width: '50%',
    marginBottom: 10
  },
  title: {
    fontSize: 10,
    marginBottom: 10,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    backgroundColor: '#CD5C63',
    color: 'white',
    paddingLeft: '5px'
  },
  subtitle: {
    fontSize: 8,
    marginBottom: 8,
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  section: {
    fontSize: 6,
    marginBottom: 6,
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  }
})

interface IContractSummaryExportProps {
  brandOptions: IOption[]
  foodtasticBrandOptions: IOption[]
  distributionCenterOptions: IOption[]
  productRows: IProductDataRow[]
  productOptions: IOption[]
  deliveryMethodOptions: IOption[]
  paymentFrequencyOptions: IOption[]
  measurementTypeOptionsVolumeRebate: IOption[]
  measurementTypeOptionsUnitRebate: IOption[]
  supplierContactName: string
  supplierContactEmail: string
  supplierPhoneLine: string
  supplierAddressLine: string
  contractStartDate: string
  contractEndDate: string
  contactName: string
  contactEmail: string
  contactPhoneLine: string
  lumpSumRebates: ILumpSumRebate[]
  volumeRebates: IVolumeRebate[]
  unitRebates: IUnitRebate[]
  volumeRebatesPaymentFrequencyId: EPaymentFrequency | null
  volumeRebatesFirstPaymentIssuedDate: string | null
  unitRebatesPaymentFrequencyId: EPaymentFrequency | null
  unitRebatesFirstPaymentIssuedDate: string | null
  contractedPrices: IContractedPrice[]
  signature: string
  comments: string
  isDistributorExport: boolean
}

export const ContractSummaryExport: React.FC<IContractSummaryExportProps> = (props) => {
  const { t } = useTranslation()
  const {
    brandOptions,
    foodtasticBrandOptions,
    distributionCenterOptions,
    // productRows,
    productOptions,
    deliveryMethodOptions,
    paymentFrequencyOptions,
    measurementTypeOptionsVolumeRebate,
    measurementTypeOptionsUnitRebate,
    supplierContactName,
    supplierContactEmail,
    supplierPhoneLine,
    supplierAddressLine,
    contractStartDate,
    contractEndDate,
    contactName,
    contactEmail,
    contactPhoneLine,
    lumpSumRebates,
    contractedPrices,
    volumeRebates,
    unitRebates,
    volumeRebatesPaymentFrequencyId,
    volumeRebatesFirstPaymentIssuedDate,
    unitRebatesPaymentFrequencyId,
    unitRebatesFirstPaymentIssuedDate,
    comments,
    signature,
    isDistributorExport
  } = props

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{t('supplierContractForm.summary.contractInformation.title')}</Text>
          <View>
            <View style={styles.contentContainer}>
              {/* First Block */}
              <View style={styles.column}>
                <Text style={styles.subtitle}>{t('supplierContractForm.summary.contractInformation.supplierContact.title')}</Text>
                <Text style={styles.section}>{supplierContactName}</Text>
                <Text style={styles.section}>{supplierContactEmail}</Text>
                <Text style={styles.section}>{supplierPhoneLine}</Text>
                <Text style={styles.section}>{supplierAddressLine}</Text>
              </View>

              {/* Second Block */}
              <View style={styles.column}>
                <Text style={styles.subtitle}>{t('supplierContractForm.summary.contractInformation.contractDates.title')}</Text>
                <Text style={styles.section}>{`${t('supplierContractForm.summary.contractInformation.contractDates.startDate')}: ${contractStartDate}`}</Text>
                <Text style={styles.section}>{`${t('supplierContractForm.summary.contractInformation.contractDates.endDate')}: ${contractEndDate}`}</Text>
              </View>
            </View>
            <View style={styles.contentContainer}>
              {/* Third Block */}
              <View style={styles.column}>
                <Text style={styles.subtitle}>{t('supplierContractForm.summary.contractInformation.contact.title')}</Text>
                <Text style={styles.section}>{contactName}</Text>
                <Text style={styles.section}>{contactEmail}</Text>
                <Text style={styles.section}>{contactPhoneLine}</Text>
              </View>
            </View>
         </View>
        <Text style={styles.title}>{t('supplierContractForm.summary.contractedPrices.title')}</Text>
        <View>
          <View style={styles.contentContainer}>
            {contractedPrices.map((entry, index) => (
              <ContractedPricesExport
                key={index}
                index={index}
                contractedPrices={entry.contractedPriceData}
                deliveryMethodOptions={deliveryMethodOptions}
                distributionCenterOptions={distributionCenterOptions}
                foodtasticBrandOptions={foodtasticBrandOptions}
                foodtasticBrandIds={entry.foodtasticBrandIds}
              />
            ))}
          </View>
        </View>
        { !isDistributorExport
          ? <>
            <Text style={styles.title}>{t('supplierContractForm.summary.lumpSum.title')}</Text>
        <View>
          <View style={styles.contentContainer}>
            {lumpSumRebates.map((rebate, index) => (
              <LumpSumExport
                key={index}
                index={index}
                lumpSumRebate={rebate}
                paymentFrequencyOptions={paymentFrequencyOptions}
              />
            ))}
          </View>
        </View>
        <Text style={styles.title}>{t('supplierContractForm.summary.volumeRebates.title')}</Text>
        <View>
          <View style={styles.contentContainer}>
            {volumeRebates.map((rebate, index) => (
              <VolumeRebatesExport
                key={rebate.id}
                index={index}
                brandOptions={brandOptions}
                foodtasticBrandOptions={foodtasticBrandOptions}
                measurementTypeOptionsVolumeRebate={measurementTypeOptionsVolumeRebate}
                productOptions={productOptions}
                volumeRebate={rebate}
              />
            ))}
            <View>
              <Text style={styles.subtitle}>{t('supplierContractForm.summary.volumeRebates.paymentConditions.title')}</Text>
              <Text style={styles.section}>{`${t('supplierContractForm.summary.volumeRebates.paymentConditions.payment')}: ${getOptionNameById(paymentFrequencyOptions, volumeRebatesPaymentFrequencyId ?? '') ?? '-'}`}</Text>
              <Text style={styles.section}>{`${t('supplierContractForm.summary.volumeRebates.paymentConditions.firstPaymentDate')}: ${volumeRebatesFirstPaymentIssuedDate ?? '-'}`}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.title}>{t('supplierContractForm.summary.unitRebates.title')}</Text>
        <View>
          <View style={styles.contentContainer}>
            {unitRebates.map((rebate, index) => (
              <UnitRebatesExport
                key={index}
                brandOptions={brandOptions}
                distributionCenterOptions={distributionCenterOptions}
                foodtasticBrandOptions={foodtasticBrandOptions}
                measurementTypeOptionsUnitRebate={measurementTypeOptionsUnitRebate}
                index={index}
                unitRebate={rebate}
              />
            ))}
            <View>
              <Text style={styles.subtitle}>{t('supplierContractForm.summary.unitRebates.paymentConditions.title')}</Text>
              <Text style={styles.section}>{`${t('supplierContractForm.summary.unitRebates.paymentConditions.payment')}: ${getOptionNameById(paymentFrequencyOptions, unitRebatesPaymentFrequencyId ?? '') ?? '-'}`}</Text>
              <Text style={styles.section}>{`${t('supplierContractForm.summary.unitRebates.paymentConditions.firstPaymentDate')}: ${unitRebatesFirstPaymentIssuedDate ?? '-'}`}</Text>
            </View>
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View>
            <Text style={styles.subtitle}>{`${t('supplierContractForm.export.comments')}: ${comments}`}</Text>
            <Text style={styles.subtitle}>{`${t('supplierContractForm.export.signature')}: ${signature}`}</Text>
          </View>
        </View>
        </>
          : <>
        </>
        }

      </Page>
    </Document>
  )
}
