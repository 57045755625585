import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { App } from './App'
import { AppLayout } from './components'
import { MsalProvider } from '@azure/msal-react'
import { EventType, type AccountInfo } from '@azure/msal-browser'
import { msalInstance } from './components/api/authUtils'

const rootElement = document.getElementById('root')

// Default to using the first account if no account is active on page load
if ((msalInstance.getActiveAccount() == null) && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

// Listen for sign-in events and set active account
msalInstance.addEventCallback((event) => {
  const account = event.payload as AccountInfo
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      account != null
  ) {
    msalInstance.setActiveAccount(account)
  }
})

if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <AppLayout>
          <MsalProvider instance={msalInstance}>
            <App/>
          </MsalProvider>
        </AppLayout>
      </I18nextProvider>
    </React.StrictMode>
  )
} else {
  console.error('Root element not found in the document.')
}
