import { LogLevel } from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: 'c7e2ca60-1184-4988-ae78-edf3103a72a4',
    authority: 'https://login.microsoftonline.com/0aab30cf-8e26-467f-91d8-69db4b716edf',
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'sessionStorage', // sessionStorage is more secure, use localStorage for SSO between tabs
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
        }
      }
    }
  }
}

export const loginRequest = {
  scopes: ['api://8933f096-45be-41db-9783-aa4cbbc5f3a5/vrms_accessall']
}
