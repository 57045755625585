import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { LanguageSwitcher } from '../commons'
import { AppBar, Avatar, Button, IconButton, Paper, Popover, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import HomeIcon from '@mui/icons-material/Home'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { login, logout, msalInstance } from '../api/authUtils'
import { LoginBox } from '../login/LoginBox'
import footerImg from '../../assets/images/login-footer.png'

interface IContentProps {
  header: React.ReactNode
  body: React.ReactNode
  footer?: React.ReactNode
}

const getInitials = (name: string): string => {
  const initials = name.match(/\b\w/g) // \b = matches the start position of the word, \w = matches any word character, g = global flag to search for all matches
  return initials == null ? '' : initials.join('').toUpperCase().slice(0, 3)
}

export const Content: React.FC<IContentProps> = ({ header, body, footer }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const account = msalInstance.getActiveAccount()
  const handleHomeButtonClick = (): void => {
    navigate('/')
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleNameClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'logout-popover' : undefined

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        flex: 1
      }}
    >
      <>
        <AppBar position="relative">
          <Toolbar sx={{ background: '#C0333C', paddingLeft: 0 }}>
            <AuthenticatedTemplate>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={handleHomeButtonClick}
              >
                <IconButton
                  sx={{
                    cursor: 'pointer',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent'
                    }
                  }}
                >
                  <HomeIcon style={{ color: '#FFFFFF' }} />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ cursor: 'pointer' }}
                >
                  {t('layout.home')}
                </Typography>
              </Box>
            </AuthenticatedTemplate>

            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h6" color="inherit" noWrap>
                {t('layout.company')}
              </Typography>
            </Box>
            <Box>
              <UnauthenticatedTemplate>
                <Box sx={{
                  display: 'inline-block',
                  alignItems: 'right',
                  cursor: 'pointer'
                }} onClick={
                    () => {
                      void (async () => {
                        await login()
                      })()
                    }
                  }>
                  <Typography variant="h6" color="inherit" noWrap>
                    { t('layout.login') }
                  </Typography>
                </Box>
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <Box>
                  <Button aria-describedby={popoverId} variant="text" color="inherit" onClick={ handleNameClick }>
                    <Avatar>{ account?.name == null ? '' : getInitials(account?.name) }</Avatar>
                  </Button>
                  <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <Paper sx={{ minWidth: '180px', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                      <Typography variant="h6" noWrap>
                        { account?.name }
                      </Typography>
                      <Typography variant="subtitle1" noWrap>
                      { account?.username }
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" color='primary' sx={{ marginTop: '10px', borderRadius: '5px' }} onClick={
                          () => {
                            void (async () => {
                              await logout()
                            })()
                          }
                          }>{t('layout.logout')}</Button>
                      </Box>
                    </Paper>
                  </Popover>
                </Box>
              </AuthenticatedTemplate>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            pt: 6,
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <AuthenticatedTemplate>
            <Container sx={{ display: 'flex', justifyContent: 'space-between' }} >
              {header}
              <LanguageSwitcher/>
            </Container>
            <Container sx={{ py: 2 }}>
              {body}
            </Container>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
              <LoginBox />
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}/>
              <Box sx={{ width: '100%' }} >
                <img src={footerImg} alt='loginImage' style={{ width: '100%', maxHeight: '300px', height: 'auto' }}/>
              </Box>
            </Box>
          </UnauthenticatedTemplate>
        </Box>
      </>
      { footer !== undefined && <Box sx={{ background: '#FAFAFA' }}>
        <Container sx={{ py: 2 }}>
          {footer}
        </Container>
      </Box>}
    </Box>
  )
}
