import type { ContractSubmitPayload, IUnitRebate, ILumpSumRebate, IVolumeRebate, ContractBrand, ContractPriceRebate, IContractForm } from '../types'

export const convertFormStateToContractPayload = (contractForm: IContractForm, active: boolean): ContractSubmitPayload => {
  const {
    supplierContact,
    contact,
    contractStartDate,
    contractEndDate,
    hasLumpSumRebate,
    hasContractedPrice,
    hasVolumeRebate,
    hasUnitRebate,
    contractedPrices,
    lumpSumRebates,
    volumeRebates,
    unitRebates,
    comments,
    attachments,
    volumeRebatesPaymentFrequencyId,
    volumeRebatesFirstPaymentIssuedDate,
    unitRebatesPaymentFrequencyId,
    unitRebatesFirstPaymentIssuedDate,
    signature,
    supplierId,
    supplierName,
    contractId
  } = contractForm

  const contractBrandItemIds: ContractBrand[] = []
  const contractPriceRebateLines: ContractPriceRebate[] = []

  contractedPrices.forEach((entry, index) => {
    const brands: ContractBrand = {
      lineid: index + 1,
      foodtasticBrandIds: entry.foodtasticBrandIds
    }
    const lines: ContractPriceRebate[] = entry.contractedPriceData.filter((row) => row.contractedPrice !== null).map((row) => {
      return {
        rebateContractID: contractId !== null ? parseInt(contractId) : null,
        lineId: brands.lineid,
        GTIN: row.productGTIN ?? '',
        supplierProductCode: row.productCode ?? '',
        productDescriptionEN: row.productDescriptionEN ?? '',
        minimumVolume: row.minimumVolume ?? 0,
        contractedPrice: row.contractedPrice ?? 0,
        distributorCenters: row.distributionCenterIds,
        dateInEffect: row.dateInEffect,
        expirationDate: row.expirationDate,
        deliveryMethod: row.deliveryMethodId ?? ''
      }
    })
    contractBrandItemIds.push(brands)
    contractPriceRebateLines.push(...lines)
  })

  const payload: ContractSubmitPayload = {
    RebateContract: [
      {
        SupplierContact: [
          {
            rebateContractID: contractId !== null ? parseInt(contractId) : null,
            firstName: supplierContact.firstName,
            middleName: supplierContact.middleName,
            lastName: supplierContact.lastName,
            phoneNumber: supplierContact.phone,
            phoneExtension: supplierContact.phoneExt,
            emailAddress: supplierContact.email,
            addressLine: supplierContact.address,
            city: supplierContact.city,
            provinceState: supplierContact.province,
            zipCode: supplierContact.zipCode
          }
        ],
        LocalContact: [
          {
            rebateContractID: contractId !== null ? +contractId : null,
            firstName: contact.firstName,
            middleName: contact.middleName,
            lastName: contact.lastName,
            phoneNumber: contact.phone,
            phoneExtension: contact.phoneExt,
            emailAddress: contact.email
          }
        ],
        contractID: contractId,
        contractStartDate,
        contractEndDate,
        supplierId: supplierId !== null && supplierId !== undefined && supplierId !== '' ? +supplierId : null,
        supplierName,
        hasLumpSumRebate,
        hasContractedPrice,
        hasVolumeRebate,
        hasUnitRebate,
        signature,
        comments,
        attachment: attachments,
        status: active,
        ContractBrandId: contractBrandItemIds,
        ContractPriceRebate: contractPriceRebateLines,
        LumpSumRebate: hasLumpSumRebate
          ? lumpSumRebates.map((lumpSumRebate: ILumpSumRebate, index: number) => ({
            rebateContractID: contractId !== null ? parseInt(contractId) : null,
            lineId: index + 1,
            lumpSumAmount: lumpSumRebate.lumpSumAmount ?? 0,
            paymentFrequency: lumpSumRebate.paymentFrequencyId ?? '',
            firstPaymentDate: lumpSumRebate.firstPaymentIssuedDate,
            firstPaymentAmount: lumpSumRebate.firstPaymentAmount ?? 0
          }))
          : [],
        VolumeRebate: hasVolumeRebate
          ? volumeRebates.map((volumeRebate: IVolumeRebate, index: number) => ({
            rebateContractID: contractId !== null ? parseInt(contractId) : null,
            lineId: index + 1,
            foodTasticBrands: volumeRebate.foodtasticBrandIds,
            supplierBrands: volumeRebate.brandIds,
            productIDs: volumeRebate.productIds,
            rebateTypeId: volumeRebate.rebateTargetTypeId !== null ? +volumeRebate.rebateTargetTypeId : 0,
            rebateMeasurementTypeId: volumeRebate.rebateMeasurementTypeId !== null ? +volumeRebate.rebateMeasurementTypeId : 0,
            thresholds: volumeRebate.thresholds.length > 0
              ? volumeRebate.thresholds.map((threshold) => {
                return {
                  amount: threshold.amount ?? 0,
                  min: threshold.min ?? 0,
                  max: threshold.max ?? 0
                }
              })
              : [],
            paymentFrequencyId: volumeRebatesPaymentFrequencyId !== null ? +volumeRebatesPaymentFrequencyId : 0,
            firstPaymentIssuedDate: volumeRebatesFirstPaymentIssuedDate
          }))
          : [],
        UnitRebate: hasUnitRebate
          ? unitRebates.map((unitRebate: IUnitRebate, index: number) => ({
            rebateContractID: contractId !== null ? parseInt(contractId) : null,
            lineId: index + 1,
            foodTasticBrands: unitRebate.foodtasticBrandIds,
            rebateTypeId: unitRebate.rebateTargetTypeId !== null ? +unitRebate.rebateTargetTypeId : 0,
            rebateDataRows: unitRebate.rebateDataRows,
            paymentFrequencyId: unitRebatesPaymentFrequencyId !== null ? +unitRebatesPaymentFrequencyId : 0,
            firstPaymentIssuedDate: unitRebatesFirstPaymentIssuedDate
          }))
          : []
      }
    ]
  }

  return payload
}
