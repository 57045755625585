import { type IDistributor, type IProduct, type ContractSubmitPayload, type ISupplierBrand, type IFoodtasticBrand, type ISupplier, type IRebateContract, type IGetContractByIdResult } from '../../types'
import { type ApiPostResponse, get, post } from './utils'

export const getSuppliers: () => Promise<ISupplier[]> = async () => {
  try {
    const response = await get<ISupplier[]>('/api/supplychain/getSuppliers/')
    return response.data
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}

export const getSuppliersById: (id: string) => Promise<ISupplier[] | null> = async (id) => {
  try {
    const response = await get<ISupplier[]>(`/api/supplychain/getSupplierByID/${id}`)
    return response.data
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}

export const getSupplierBrandsBySupplierId: (supplierId: string) => Promise<ISupplierBrand[]> = async (supplierId) => {
  try {
    const response = await get<ISupplierBrand[]>(`/api/supplychain/getSupplierBrands/${supplierId}`)
    return response.data
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}

export const getContractById: (id: string) => Promise<IRebateContract[]> = async (id) => {
  try {
    const response = await get<IGetContractByIdResult>(`/api/supplychain/getRebateContractByID/${id}`)
    return response.data.RebateContract
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}

export const getFoodtasticBrands: () => Promise<IFoodtasticBrand[]> = async () => {
  try {
    const response = await get<IFoodtasticBrand[]>('/api/supplychain/getFoodTasticBrands')
    return response.data
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}

export const getProductsByBrandId: (brandId: string) => Promise<IProduct[]> = async (brandId) => {
  try {
    const response = await get<IProduct[]>(`/api/supplychain/getItemsByBrands/${brandId}`)
    return response.data
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}

export const getProductsBySupplierId: (supplierId: string) => Promise<IProduct[]> = async (supplierId) => {
  try {
    const response = await get<IProduct[]>(`/api/supplychain/getItemsBySupplier/${supplierId}`)
    return response.data
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}

export const getDistributors: () => Promise<IDistributor[]> = async () => {
  try {
    const response = await get<IDistributor[]>('/api/supplychain/getDistributors')
    return response.data
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}

export const submitForm = async (payload: ContractSubmitPayload): Promise<number> => {
  try {
    const response: ApiPostResponse<number> = await post<number>('/api/supplychain/createRebateContract', payload)
    return response.data
  } catch (error) {
    console.error('Error submitting form:', error)
    return 0
  }
}
