import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import { getRequestConfig } from './authUtils'

export type ApiResponse<T> = AxiosResponse<T>

export interface ApiPostResponse<T> {
  data: T
  status: number
  statusText: string
  headers: any
  config: AxiosRequestConfig
  request?: any
}

export async function get<T> (
  url: string
): Promise<ApiResponse<T>> {
  const config = await getRequestConfig()
  const response = await axios.get<T>(url, config)
  return response
}

export async function post<T> (
  url: string,
  data?: any
): Promise<ApiPostResponse<T>> {
  const config = await getRequestConfig()
  const response: AxiosResponse<T> = await axios.post(url, data, config)

  const apiResponse: ApiPostResponse<T> = {
    data: response.data,
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    config: response.config,
    request: response.request
  }

  return apiResponse
}
