import { PDFDownloadLink } from '@react-pdf/renderer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContractSummaryExport } from './ContractSummaryExport'
import { Button } from '@mui/material'
import { isEmpty, join } from '../../../../utils'
import { useSupplierForm } from '../../../../stores/SupplierContractForm'
import { useOptions } from '../../../../stores/Options'

interface IDownloadPdfProps {
  isDistributorExport: boolean
}

export const DownloadPDF: React.FC<IDownloadPdfProps> = ({ isDistributorExport }) => {
  const { t } = useTranslation()
  const { state } = useSupplierForm()
  const { state: options } = useOptions()
  const contractForm = state.contractForm
  const contact = contractForm.contact
  const supplierContact = contractForm.supplierContact

  const contractSummaryExportProps = {
    ...options,
    supplierContactName: join(' ', supplierContact.firstName, supplierContact.middleName, supplierContact.lastName),
    supplierContactEmail: supplierContact.email,
    supplierPhoneLine: join(', ext. ', supplierContact.phone, supplierContact.phoneExt),
    supplierAddressLine: join(', ', supplierContact.address, supplierContact.city, supplierContact.province, supplierContact.zipCode),
    contractStartDate: isEmpty(contractForm.contractStartDate) ? '-' : contractForm.contractStartDate,
    contractEndDate: isEmpty(contractForm.contractEndDate) ? '-' : contractForm.contractEndDate,
    contactName: join(' ', contact.firstName, contact.middleName, contact.lastName),
    contactEmail: contact.email,
    contactPhoneLine: join(', ext. ', contact.phone, contact.phoneExt),
    lumpSumRebates: contractForm.lumpSumRebates,
    contractedPrices: contractForm.contractedPrices,
    volumeRebates: contractForm.volumeRebates,
    volumeRebatesPaymentFrequencyId: contractForm.volumeRebatesPaymentFrequencyId,
    volumeRebatesFirstPaymentIssuedDate: contractForm.volumeRebatesFirstPaymentIssuedDate,
    unitRebates: contractForm.unitRebates,
    unitRebatesPaymentFrequencyId: contractForm.unitRebatesPaymentFrequencyId,
    unitRebatesFirstPaymentIssuedDate: contractForm.unitRebatesFirstPaymentIssuedDate,
    signature: contractForm.signature,
    comments: contractForm.comments,
    isDistributorExport
  }

  return (
    <PDFDownloadLink document={<ContractSummaryExport {...contractSummaryExportProps}/>} fileName= {isDistributorExport ? 'distributor-contract-summary.pdf' : 'contract-summary.pdf'}>
    {({ loading }) => (
      <Button
        variant="contained"
        color="primary"
        disabled={loading}
      >
        {loading ? t('commons.buttons.loading') : isDistributorExport ? t('commons.buttons.downloadDistributorPDF') : t('commons.buttons.downloadPDF')}
      </Button>
    )}
  </PDFDownloadLink>
  )
}
