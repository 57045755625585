import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../../stores/SupplierContractForm'
import { isEmpty, join } from '../../../../utils'

export const ContactInformationSummary: React.FC = () => {
  const { t } = useTranslation()
  const { state } = useSupplierForm()
  const supplierContact = state.contractForm.supplierContact
  const contact = state.contractForm.contact
  const contractForm = state.contractForm

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Typography>
          <Typography fontWeight="bold" variant="subtitle1" gutterBottom>{t('supplierContractForm.summary.contractInformation.supplierContact.title')}</Typography>
          <Typography variant="body1" gutterBottom>{join(' ', supplierContact.firstName, supplierContact.middleName, supplierContact.lastName)}</Typography>
          <Typography variant="body1" gutterBottom>{contractForm.supplierContact.email}</Typography>
          <Typography variant="body1" gutterBottom>{join(', ext. ', supplierContact.phone, supplierContact.phoneExt)}</Typography>
          <Typography variant="body1" gutterBottom>{join(', ', supplierContact.address, supplierContact.city, supplierContact.province, supplierContact.zipCode)}</Typography>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <Typography fontWeight="bold" variant="subtitle1" gutterBottom>{t('supplierContractForm.summary.contractInformation.contractDates.title')}</Typography>
          <Typography variant="body1" gutterBottom>{`${t('supplierContractForm.summary.contractInformation.contractDates.startDate')}: ${isEmpty(contractForm.contractStartDate) ? '-' : contractForm.contractStartDate}`}</Typography>
          <Typography variant="body1" gutterBottom>{`${t('supplierContractForm.summary.contractInformation.contractDates.endDate')}: ${isEmpty(contractForm.contractEndDate) ? '-' : contractForm.contractEndDate}`}</Typography>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <Typography fontWeight="bold" variant="subtitle1" gutterBottom>{t('supplierContractForm.summary.contractInformation.contact.title')}</Typography>
          <Typography variant="body1" gutterBottom>{join(' ', contact.firstName, contact.middleName, contact.lastName)}</Typography>
          <Typography variant="body1" gutterBottom>{contractForm.supplierContact.email}</Typography>
          <Typography variant="body1" gutterBottom>{join(', ext. ', supplierContact.phone, supplierContact.phoneExt)}</Typography>
        </Typography>
      </Grid>
    </Grid>
  )
}
