import { type IRebateContractListItem, type IRebateContractResult } from '../../types'
import { get } from './utils'

export const getContractList: () => Promise<IRebateContractListItem[]> = async () => {
  try {
    const response = await get<IRebateContractResult>('/api/supplychain/getRebateContracts')
    return response.data.RebateContract
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}

export const getContractListBySupplierId: (supplierId: string) => Promise<IRebateContractListItem[]> = async (supplierId) => {
  try {
    const response = await get<IRebateContractResult>(`/api/supplychain/getRebateContracts/${supplierId}`)
    return response.data.RebateContract
  } catch (e) {
    console.log('error: ', e)
    return []
  }
}
