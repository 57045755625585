import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation()
  const [lang, setLang] = React.useState('en')

  const changeLanguage = (event: React.MouseEvent<HTMLElement>, newLang: string): void => {
    i18n.changeLanguage(newLang)
      .then(() => {
        setLang(newLang)
      })
      .catch((error) => {
        console.error('Error changing language:', error)
      })
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={lang}
      exclusive
      onChange={changeLanguage}
      aria-label="Language"
    >
      <ToggleButton value="en">EN</ToggleButton>
      <ToggleButton value="fr">FR</ToggleButton>
    </ToggleButtonGroup>
  )
}
