import { EOptionsMenuStateType, type IOptionsMenuErrorPayload, type IOptionsMenuAction, type IOptionsMenuState, type IOptionsMenuPayload } from '../types'

export const optionsReducer: (state: IOptionsMenuState, action: IOptionsMenuAction) => IOptionsMenuState = (state, action) => {
  let payload = null
  switch (action.type) {
    case EOptionsMenuStateType.SET_ERROR:
      payload = action.payload as IOptionsMenuErrorPayload
      return {
        ...state,
        loadingError: payload.loadingError,
        loading: false
      }
    case EOptionsMenuStateType.SET_OPTIONS:
      payload = action.payload as IOptionsMenuPayload
      return {
        ...state,
        brandOptions: payload.brandOptions,
        foodtasticBrandOptions: payload.foodtasticBrandOptions,
        distributionCenterOptions: payload.distributionCenterOptions,
        productRows: payload.productRows,
        productOptions: payload.productOptions,
        deliveryMethodOptions: payload.deliveryMethodOptions,
        paymentFrequencyOptions: payload.paymentFrequencyOptions,
        measurementTypeOptionsVolumeRebate: payload.measurementTypeOptionsVolumeRebate,
        measurementTypeOptionsUnitRebate: payload.measurementTypeOptionsUnitRebate,
        loading: false
      }
    default:
      return state
  }
}
