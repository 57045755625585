import { type ISupplierContractFormAction, ERebateTargetType, type IContractForm, ESupplierContractFormActionType, type IUnitRebateProductRow, type IUnitRebateBrandRow, type ITabErrors } from '../types'
import { isEmpty, isValidEmail, isValidPhoneNumber } from './helpers'

export const validateForm = (dispatch: React.Dispatch<ISupplierContractFormAction>, isDraft: boolean, contractForm: IContractForm): { isValid: boolean, tabErrors: ITabErrors } => {
  if (isDraft) {
    return {
      isValid: true,
      tabErrors: {
        contractInformationTabError: false,
        contractedPricesTabError: false,
        lumpSumTabError: false,
        volumeRebatesTabError: false,
        unitRebatesTabError: false,
        summaryTabError: false
      }
    }
  }

  // -------------------Start Contract Information Validation---------------------------
  const supplierContactPhoneError = !isEmpty(contractForm.supplierContact.phone) && !isValidPhoneNumber(contractForm.supplierContact.phone)
  const supplierContactEmailError = !isEmpty(contractForm.supplierContact.email) && !isValidEmail(contractForm.supplierContact.email)

  const contactPhoneError = !isEmpty(contractForm.contact.phone) && !isValidPhoneNumber(contractForm.contact.phone)
  const contactEmailError = !isEmpty(contractForm.contact.email) && !isValidEmail(contractForm.contact.email)

  const supplierContactErrors = {
    phone: supplierContactPhoneError,
    email: supplierContactEmailError
  }

  const contactErrors = {
    phone: contactPhoneError,
    email: contactEmailError
  }

  const supplierContactIsValid = Object.values(supplierContactErrors).every((value) => !value)
  const contactIsValid = Object.values(contactErrors).every((value) => !value)
  const contractStartDateError = isEmpty(contractForm.contractStartDate)
  const contractEndDateError = !isEmpty(contractForm.contractEndDate) && contractForm.contractStartDate > contractForm.contractEndDate

  // -------------------End Contract Information Validation---------------------------

  // -------------------Start Contracted Prices Validation----------------------------

  let contractedPricesAreValid = true
  const contractedPricesErrors: any = {}
  contractForm.contractedPrices.forEach((entry) => {
    const foodtasticBrandIdsError = entry.foodtasticBrandIds.length <= 0
    let rowsAreValid = true
    const rowsErrors: any = {}
    entry.contractedPriceData.forEach((row) => {
      const dateInEffectError = row.contractedPrice !== null ? isEmpty(row.dateInEffect) : false
      const expirationDateError = row.contractedPrice !== null ? !isEmpty(row.expirationDate) && row.dateInEffect > row.expirationDate : false
      if (dateInEffectError || expirationDateError) {
        rowsAreValid = false
      }
      rowsErrors[row.id] = {
        dateInEffect: dateInEffectError,
        expirationDate: expirationDateError
      }
    })
    if (foodtasticBrandIdsError || !rowsAreValid) {
      contractedPricesAreValid = false
    }
    contractedPricesErrors[entry.id] = {
      foodtasticBrandIds: foodtasticBrandIdsError,
      rowsErrors
    }
  })

  // -------------------End Contracted Prices Validation------------------------------

  // -------------------Start Lump Sum Validation-------------------------------------

  // Lump Sum
  let lumpSumAreValid = true
  const lumpSumErrors: any = {}
  contractForm.lumpSumRebates.forEach((rebate) => {
    const amountError = rebate.lumpSumAmount === null
    if (amountError) {
      lumpSumAreValid = false
    }
    lumpSumErrors[rebate.id] = {
      lumpSumAmount: amountError
    }
  })

  // -------------------End Lump Sum Validation---------------------------------------

  // -------------------Start Volume Rebates Validation-------------------------------
  const volumeRebatesErrors: any = {}
  let volumeRebatesAreValid = true

  contractForm.volumeRebates.forEach((rebate) => {
    const foodtasticBrandIdsError = rebate.foodtasticBrandIds.length <= 0
    const rebateTargetTypeIdError = rebate.rebateTargetTypeId === null
    const rebateMeasurementTypeIdError = rebate.thresholds.length > 0 && rebate.rebateMeasurementTypeId === null
    const brandIdsErrors = rebate.rebateTargetTypeId === ERebateTargetType.PRODUCT_BRAND && rebate.brandIds.length <= 0
    const productIdsErrors = rebate.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT && rebate.productIds.length <= 0
    let thresholdsAreValid = true
    const volumeThresholdErrors: any = {}
    rebate.thresholds.forEach((threshold) => {
      const minError = threshold.min === null
      const amountError = threshold.amount === null
      if (minError || amountError) {
        thresholdsAreValid = false
      }
      volumeThresholdErrors[threshold.id] = {
        min: minError,
        amount: amountError
      }
    })
    if (rebateTargetTypeIdError || rebateMeasurementTypeIdError || brandIdsErrors || productIdsErrors || !thresholdsAreValid) {
      volumeRebatesAreValid = false
    }
    volumeRebatesErrors[rebate.id] = {
      foodtasticBrandIds: foodtasticBrandIdsError,
      brandIds: brandIdsErrors,
      productIds: productIdsErrors,
      rebateTargetTypeId: rebateTargetTypeIdError,
      rebateMeasurementTypeId: rebateMeasurementTypeIdError,
      thresholds: volumeThresholdErrors
    }
  })

  // -------------------End Volume Rebates Validation-------------------------------

  // -------------------Start Unit Rebates Validation-------------------------------
  const unitRebatesError: any = {}
  let unitRebatesAreValid = true

  contractForm.unitRebates.forEach((rebate) => {
    const foodtasticBrandIdsError = rebate.foodtasticBrandIds.length <= 0
    const rebateTargetTypeIdError = rebate.rebateTargetTypeId === null
    const rebateDataRowsErrors: any = {}
    let rowsAreValid = true
    rebate.rebateDataRows.forEach((row) => {
      if (rebate.rebateTargetTypeId === ERebateTargetType.ALL_SALES) {
        const distributionCenterIdsError = row.distributionCenterIds.length <= 0
        const productBrandIdError = false
        const gtinError = false
        const productDescriptionENError = false
        const productCodeError = false
        const rebateMeasurementTypeIdError = row.rebateMeasurementTypeId === null
        const amountError = row.amount === null
        if (distributionCenterIdsError || productBrandIdError || gtinError || productDescriptionENError || productCodeError || rebateMeasurementTypeIdError || amountError) {
          rowsAreValid = false
        }

        rebateDataRowsErrors[row.id] = {
          distributionCenterIds: distributionCenterIdsError,
          productBrandId: productBrandIdError,
          gtin: gtinError,
          productDescriptionEN: productDescriptionENError,
          productCode: productCodeError,
          rebateMeasurementTypeId: rebateMeasurementTypeIdError,
          amount: amountError
        }
      } else if (rebate.rebateTargetTypeId === ERebateTargetType.PRODUCT_BRAND) {
        const typedRow = row as IUnitRebateBrandRow
        const distributionCenterIdsError = typedRow.distributionCenterIds.length <= 0
        const productBrandIdError = isEmpty(typedRow.productBrandId ?? '')
        const gtinError = false
        const productDescriptionENError = false
        const productCodeError = false
        const rebateMeasurementTypeIdError = typedRow.rebateMeasurementTypeId === null
        const amountError = typedRow.amount === null
        if (distributionCenterIdsError || productBrandIdError || gtinError || productDescriptionENError || productCodeError || rebateMeasurementTypeIdError || amountError) {
          rowsAreValid = false
        }
        rebateDataRowsErrors[row.id] = {
          distributionCenterIds: distributionCenterIdsError,
          productBrandId: productBrandIdError,
          gtin: gtinError,
          productDescriptionEN: productDescriptionENError,
          productCode: productCodeError,
          rebateMeasurementTypeId: rebateMeasurementTypeIdError,
          amount: amountError
        }
      } else if (rebate.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT) {
        const typedRow = row as IUnitRebateProductRow
        const distributionCenterIdsError = typedRow.distributionCenterIds.length <= 0
        const productBrandIdError = false
        const gtinError = isEmpty(typedRow.gtin ?? '')
        const productDescriptionENError = isEmpty(typedRow.productDescriptionEN ?? '')
        const productCodeError = isEmpty(typedRow.productCode ?? '')
        const rebateMeasurementTypeIdError = typedRow.rebateMeasurementTypeId === null
        const amountError = typedRow.amount === null
        if (distributionCenterIdsError || productBrandIdError || gtinError || productDescriptionENError || productCodeError || rebateMeasurementTypeIdError || amountError) {
          rowsAreValid = false
        }
        rebateDataRowsErrors[row.id] = {
          distributionCenterIds: distributionCenterIdsError,
          productBrandId: productBrandIdError,
          gtin: gtinError,
          productDescriptionEN: productDescriptionENError,
          productCode: productCodeError,
          rebateMeasurementTypeId: rebateMeasurementTypeIdError,
          amount: amountError
        }
      }
    })
    if (foodtasticBrandIdsError || rebateTargetTypeIdError || !rowsAreValid) {
      unitRebatesAreValid = false
    }
    unitRebatesError[rebate.id] = {
      foodtasticBrandIds: foodtasticBrandIdsError,
      rebateTargetTypeId: rebateTargetTypeIdError,
      rebateDataRows: rebateDataRowsErrors
    }
  })

  // -------------------End Unit Rebates Validation-------------------------------

  const setError = {
    type: ESupplierContractFormActionType.VALIDATE_FORM,
    payload: {
      supplierContact: supplierContactErrors,
      contact: contactErrors,
      contractStartDate: contractStartDateError,
      contractEndDate: contractEndDateError,
      contractedPrices: contractedPricesErrors,
      lumpSumRebates: lumpSumErrors,
      volumeRebates: volumeRebatesErrors,
      unitRebates: unitRebatesError
    }
  }
  dispatch(setError)

  const formValid = supplierContactIsValid &&
    contactIsValid &&
    !contractStartDateError &&
    !contractEndDateError &&
    contractedPricesAreValid &&
    lumpSumAreValid &&
    volumeRebatesAreValid &&
    unitRebatesAreValid

  return {
    isValid: formValid,
    tabErrors: {
      contractInformationTabError: !supplierContactIsValid || !contactIsValid || contractStartDateError || contractEndDateError,
      contractedPricesTabError: !contractedPricesAreValid,
      lumpSumTabError: !lumpSumAreValid,
      volumeRebatesTabError: !volumeRebatesAreValid,
      unitRebatesTabError: !unitRebatesAreValid,
      summaryTabError: false // There are no required fields currently on the summary page, but kept the logic in case
    }
  }
}
