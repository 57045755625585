export interface IOption {
  id: string
  name: string
}

interface ISupplierContactInformation {
  firstName: string
  middleName: string
  lastName: string
  phone: string
  phoneExt: string
  email: string
  address: string
  city: string
  province: string
  zipCode: string
}

interface IFoodtasticContactInformation {
  firstName: string
  middleName: string
  lastName: string
  phone: string
  phoneExt: string
  email: string
}

export enum ERebateType {
  LUMP_SUM = '1',
  CONTRACTED_PRICES = '2',
  VOLUME = '3',
  UNIT = '4'
}

export enum EDeliveryMethods {
  DISTRIBUTOR = '1',
  PICK_UP = '2'
}

export interface IContractIds {
  contractId: string | null
  supplierId: string | null
}

export interface ILoadingError {
  errorLoadingState: boolean
}

export interface IContractInformation {
  supplierContact: ISupplierContactInformation
  contact: IFoodtasticContactInformation
  contractStartDate: string
  contractEndDate: string
  hasLumpSumRebate: boolean
  hasContractedPrice: boolean
  hasVolumeRebate: boolean
  hasUnitRebate: boolean
}

export interface IContractedPrices {
  hasContractedPrice: boolean
  contractedPrices: IContractedPrice[]
}

export interface ILumpSum {
  hasLumpSumRebate: boolean
  lumpSumRebates: ILumpSumRebate[]
}

export interface IVolumeRebates {
  hasVolumeRebate: boolean
  volumeRebates: IVolumeRebate[]
  volumeRebatesPaymentFrequencyId: EPaymentFrequency | null
  volumeRebatesFirstPaymentIssuedDate: string
}

export interface IUnitRebates {
  hasUnitRebate: boolean
  unitRebates: IUnitRebate[]
  unitRebatesPaymentFrequencyId: EPaymentFrequency | null
  unitRebatesFirstPaymentIssuedDate: string
}

export interface ISummary {
  comments: string
  attachments: string
  signature: string
}

export enum EPaymentFrequency {
  MONTHLY = '1',
  QUARTERLY = '2',
  BI_YEARLY = '3',
  YEARLY = '4'
}

export interface ILumpSumRebate {
  id: string
  lumpSumAmount: number | null
  paymentFrequencyId: EPaymentFrequency | null
  firstPaymentIssuedDate: string
  firstPaymentAmount: number | null
}

export enum ERebateTargetType {
  ALL_SALES = '1',
  PRODUCT_BRAND = '2',
  SPECIFIC_PRODUCT = '3'
}

export enum EMeasurementType {
  CASE = '1',
  DOLLARS = '2',
  PERCENTAGE = '3',
  WEIGHT_KG = '4',
  WEIGHT_G = '5',
  WEIGHT_L = '6',
  WEIGHT_ML = '7',
  WEIGHT_LBS = '8',
}

export enum EContractStatus {
  ACTIVE = '1',
  INACTIVE = '2',
  DRAFT = '3',
  PENDING = '4'
}

export interface IVolumeRebateThreshold {
  id: string
  amount: number | null
  min: number | null
  max: number | null
}

export interface IProductDataRow {
  id: string
  productGTIN: string
  productCode: string | null
  productDescriptionEN: string | null
  minimumVolume: number | null
  contractedPrice: number | null
  distributionCenterIds: string[]
  dateInEffect: string
  expirationDate: string
  deliveryMethodId: string | null
}

export interface IContractedPrice {
  id: string
  foodtasticBrandIds: string[]
  contractedPriceData: IProductDataRow[]
}

export interface IVolumeRebate {
  id: string
  foodtasticBrandIds: string[]
  brandIds: string[]
  productIds: string[]
  rebateTargetTypeId: ERebateTargetType | null
  rebateMeasurementTypeId: EMeasurementType | null
  thresholds: IVolumeRebateThreshold[]
}

export interface IUnitRebateAllSalesRow {
  id: string
  distributionCenterIds: string[]
  rebateMeasurementTypeId: EMeasurementType | null
  amount: number | null
}

export interface IUnitRebateBrandRow {
  id: string
  distributionCenterIds: string[]
  productBrandId: string | null
  rebateMeasurementTypeId: EMeasurementType | null
  amount: number | null
}

export interface IUnitRebateProductRow {
  id: string
  distributionCenterIds: string[]
  gtin: string | null
  productDescriptionEN: string | null
  productCode: string | null
  rebateMeasurementTypeId: EMeasurementType | null
  amount: number | null
}

export interface IUnitRebate {
  id: string
  foodtasticBrandIds: string[]
  rebateTargetTypeId: ERebateTargetType
  rebateDataRows: IUnitRebateBrandRow[] | IUnitRebateProductRow[] | IUnitRebateAllSalesRow[]
}

export interface IContractForm extends IContractInformation, IContractedPrices, ILumpSum, IVolumeRebates, IUnitRebates, ISummary {
  contractId: string | null
  supplierId: string | null
  supplierName: string
  status: boolean
}

export interface IUnitRebateRowsError {
  distributionCenterIds: boolean
  productBrandId: boolean
  gtin: boolean
  productDescriptionEN: boolean
  productCode: boolean
  rebateMeasurementTypeId: boolean
  amount: boolean
}

export interface IUnitRebateError {
  foodtasticBrandIds: boolean
  rebateTargetTypeId: boolean
  rebateDataRows: IUnitRebateRowsError[]
}

export interface IVolumeRebateThresholdError {
  amount: boolean
  min: boolean
  max: boolean
}

export interface IVolumeRebateError {
  foodtasticBrandIds: boolean
  brandIds: boolean
  productIds: boolean
  rebateTargetTypeId: boolean
  rebateMeasurementTypeId: boolean
  thresholds: IVolumeRebateThresholdError[]
}

export interface ILumpSumError {
  lumpSumAmount: boolean
  paymentFrequencyId: boolean
  firstPaymentIssuedDate: boolean
  firstPaymentAmount: boolean
}

interface ISupplierContactErrors {
  phone: boolean
  email: boolean
}

interface IContactErrors {
  phone: boolean
  email: boolean
}

export interface IProductDataRowErrors {
  dateInEffect: boolean
  expirationDate: boolean
}

export interface IContractedPricesError {
  foodtasticBrandIds: boolean
  contractedPriceData: IProductDataRowErrors[]
}

export interface IContractFormErrors {
  // Contact Information
  supplierContact: ISupplierContactErrors
  contact: IContactErrors
  contractStartDate: boolean
  contractEndDate: boolean
  // Contracted Prices
  contractedPrices: any
  // Lump Sum
  lumpSumRebates: any
  // Volume Rebates
  volumeRebates: any
  // Unit Rebates
  unitRebates: any
}

export interface ITabErrors {
  contractInformationTabError: boolean
  contractedPricesTabError: boolean
  lumpSumTabError: boolean
  volumeRebatesTabError: boolean
  unitRebatesTabError: boolean
  summaryTabError: boolean
}

export interface ISupplierContractFormState {
  contractForm: IContractForm
  contractFormErrors: IContractFormErrors
  loading: boolean
  errorLoadingState: boolean
}

export type TSupplierContractFormActionPayload = IContractInformation | IContractedPrices | ILumpSum | IVolumeRebates | IUnitRebates | ISummary | IContractForm | ILoadingError | IContractIds | IContractFormErrors

export enum ESupplierContractFormActionType {
  LOAD_CONTRACT = 'LOAD_CONTRACT',
  SET_LOADING_ERROR = 'SET_LOADING_ERROR',
  UPDATE_CONTRACT_INFORMATION = 'UPDATE_CONTRACT_INFORMATION',
  UPDATED_CONTRACTED_PRICES = 'UPDATED_CONTRACTED_PRICES',
  UPDATE_LUMP_SUM = 'UPDATE_LUMP_SUM',
  UPDATE_VOLUME_REBATES = 'UPDATE_VOLUME_REBATES',
  UPDATE_UNIT_REBATES = 'UPDATE_UNIT_REBATES',
  UPDATE_SUMMARY_DATA = 'UPDATE_SUMMARY_DATA',
  VALIDATE_FORM = 'VALIDATE_FORM'
}

export interface ISupplierContractFormAction {
  type: ESupplierContractFormActionType
  payload: TSupplierContractFormActionPayload
}

export interface ISupplierContractFormContext {
  state: ISupplierContractFormState
  dispatch: React.Dispatch<ISupplierContractFormAction>
}

export interface IContractListItem {
  id: string
  supplierId: number
  supplierName: string
  contractNo: string
  assignedTo: string
  lastModified: string
  endDate: string
  status: string
}

export interface IContractsListState {
  contracts: IContractListItem[]
  suppliers: IOption[]
  loading: boolean
}

export enum EContractsListActionType {
  SET_LIST_DATA = 'SET_LIST_DATA'
}

export interface IContractsListPayload {
  contracts: IContractListItem[]
  suppliers: IOption[]
}

export interface IContractsListAction {
  type: EContractsListActionType
  payload: IContractsListPayload
}

export interface IContractsListContext {
  state: IContractsListState
  dispatch: React.Dispatch<IContractsListAction>
}

export interface IOptionsMenuState {
  brandOptions: IOption[]
  foodtasticBrandOptions: IOption[]
  distributionCenterOptions: IOption[]
  productRows: IProductDataRow[]
  productOptions: IOption[]
  deliveryMethodOptions: IOption[]
  paymentFrequencyOptions: IOption[]
  measurementTypeOptionsVolumeRebate: IOption[]
  measurementTypeOptionsUnitRebate: IOption[]
  loading: boolean
  loadingError: boolean
}

export interface IOptionsMenuPayload {
  brandOptions: IOption[]
  foodtasticBrandOptions: IOption[]
  distributionCenterOptions: IOption[]
  productRows: IProductDataRow[]
  productOptions: IOption[]
  deliveryMethodOptions: IOption[]
  paymentFrequencyOptions: IOption[]
  measurementTypeOptionsVolumeRebate: IOption[]
  measurementTypeOptionsUnitRebate: IOption[]
}

export interface IOptionsMenuErrorPayload {
  loadingError: boolean
}

export enum EOptionsMenuStateType {
  SET_OPTIONS = 'SET_OPTIONS',
  SET_ERROR = 'SET_ERROR'
}

export interface IOptionsMenuAction {
  type: EOptionsMenuStateType
  payload: IOptionsMenuPayload | IOptionsMenuErrorPayload
}

export interface IOptionsMenuContext {
  state: IOptionsMenuState
  dispatch: React.Dispatch<IOptionsMenuAction>
}
