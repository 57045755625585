import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'

interface IAppLayout {
  children: React.ReactNode
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#C0333C'
    }
  },
  typography: {
    fontSize: 12
  }
})

export const AppLayout: React.FC<IAppLayout> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
