import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, type SelectChangeEvent, Switch, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../stores/SupplierContractForm'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { type ISupplierContractFormAction, ESupplierContractFormActionType, type IUnitRebate, ERebateTargetType, EPaymentFrequency, type IUnitRebates, type IUnitRebateBrandRow, EMeasurementType, type IUnitRebateProductRow, type IUnitRebateAllSalesRow, type IProductDataRow } from '../../../types'
import { type IHeader, MultiSelect, SimpleTable } from '../../commons'
import { useOptions } from '../../../stores/Options'
import { v4 as uuid } from 'uuid'
import { selectMenuProps, widgetProps } from '../../../utils'
import { CustomTextField as TextField } from '../../commons'

const initialRow: IUnitRebateAllSalesRow = {
  id: uuid(),
  distributionCenterIds: [],
  rebateMeasurementTypeId: null,
  amount: null
}

export const UnitRebates: React.FC = (props) => {
  const { state, dispatch } = useSupplierForm()
  const { state: options } = useOptions()
  const { t } = useTranslation()
  const contractForm = state.contractForm
  const validationErrors = state.contractFormErrors

  const getProductRowByGtin: (gtin: string) => IProductDataRow | undefined = (gtin) => {
    return options.productRows.find((productRow) => productRow.productGTIN === gtin)
  }

  const save: (payload: IUnitRebates) => void = (payload) => {
    const action: ISupplierContractFormAction = {
      type: ESupplierContractFormActionType.UPDATE_UNIT_REBATES,
      payload
    }
    dispatch(action)
  }

  const handleAddEntry: () => void = () => {
    const newEntry: IUnitRebate = {
      id: uuid(),
      foodtasticBrandIds: [],
      rebateTargetTypeId: ERebateTargetType.ALL_SALES,
      rebateDataRows: [{ ...initialRow }]
    }
    const newEntries = [...contractForm.unitRebates, newEntry]
    save({ ...contractForm, unitRebates: newEntries })
  }

  const handleRemoveEntry: (index: number) => void = (index) => {
    const updatedEntries = [...contractForm.unitRebates]
    updatedEntries.splice(index, 1)
    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleFoodtasticBrandsChange: (newValues: string[], index: number) => void = (newValues, index) => {
    const updatedEntries = [...contractForm.unitRebates]
    const updatedEntry = { ...contractForm.unitRebates[index] }
    updatedEntry.foodtasticBrandIds = newValues
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleRebateTargetTypeChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void = (event, index) => {
    const updatedEntries = [...contractForm.unitRebates]
    const updatedEntry = { ...contractForm.unitRebates[index] }
    updatedEntry.rebateTargetTypeId = event.target.value as ERebateTargetType
    if (event.target.value === ERebateTargetType.ALL_SALES) {
      updatedEntry.rebateDataRows = [{ ...initialRow }]
    } else {
      updatedEntry.rebateDataRows = []
    }
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleChangeAddUnitRebate: () => void = () => {
    const newValue = !contractForm.hasUnitRebate
    if (!newValue) {
      save({
        ...contractForm,
        hasUnitRebate: newValue
      })
    } else {
      save({ ...contractForm, hasUnitRebate: newValue })
    }
  }

  const handleFirstPaymentDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newPaymentDate = event.target.value
    save({ ...contractForm, unitRebatesFirstPaymentIssuedDate: newPaymentDate })
  }

  const handlePaymentFrequencyChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newFrequencyId = event.target.value as EPaymentFrequency
    save({ ...contractForm, unitRebatesPaymentFrequencyId: newFrequencyId })
  }

  const allSalesHeaders: IHeader[] = [
    {
      key: 'distributionCenters',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.allSales.distributionCenters')
    },
    {
      key: 'measurementUnit',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.allSales.measurementUnit')
    },
    {
      key: 'rebateAmount',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.allSales.rebateAmount')
    }
  ]

  const specificBrandsHeaders: IHeader[] = [
    {
      key: 'distributionCenters',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.distributionCenters')
    },
    {
      key: 'productBrand',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.brand')
    },
    {
      key: 'measurementUnit',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.measurementUnit')
    },
    {
      key: 'rebateAmount',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificBrands.rebateAmount')
    }
  ]

  const specificProductsHeaders: IHeader[] = [
    {
      key: 'distributionCenters',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.distributionCenters')
    },
    {
      key: 'GTIN',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.GTIN')
    },
    {
      key: 'productCode',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.productCode')
    },
    {
      key: 'description',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.description')
    },
    {
      key: 'measurementUnit',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.measurementUnit')
    },
    {
      key: 'rebateAmount',
      displayValue: t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.headers.specificProducts.rebateAmount')
    }
  ]

  const handleDistributionCenterChange: (indexEntry: number, indexRow: number, newValues: string[]) => void = (
    indexEntry,
    indexRow,
    newValues
  ) => {
    const updatedEntries = contractForm.unitRebates.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          rebateDataRows: entry.rebateDataRows.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                distributionCenterIds: newValues
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleMeasurementTypeChange: (indexEntry: number, indexRow: number, newValue: EMeasurementType) => void = (
    indexEntry,
    indexRow,
    newValue
  ) => {
    const updatedEntries = contractForm.unitRebates.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          rebateDataRows: (entry.rebateDataRows as IUnitRebateBrandRow[]).map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                rebateMeasurementTypeId: newValue
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleAmountChange: (indexEntry: number, indexRow: number, newValue: number) => void = (
    indexEntry,
    indexRow,
    newValue
  ) => {
    const updatedEntries = contractForm.unitRebates.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          rebateDataRows: entry.rebateDataRows.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                amount: newValue
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleBrandChange: (indexEntry: number, indexRow: number, newValues: number) => void = (
    indexEntry,
    indexRow,
    newValues
  ) => {
    const updatedEntries = contractForm.unitRebates.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          rebateDataRows: entry.rebateDataRows.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                productBrandId: newValues
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleGTINChange: (indexEntry: number, indexRow: number, newValue: string) => void = (
    indexEntry,
    indexRow,
    newValue
  ) => {
    const productRow = getProductRowByGtin(newValue)
    const updatedEntries = contractForm.unitRebates.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          rebateDataRows: entry.rebateDataRows.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                gtin: newValue,
                productCode: productRow?.productCode,
                productDescriptionEN: productRow?.productDescriptionEN
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleProductCodeChange: (indexEntry: number, indexRow: number, newValue: string) => void = (
    indexEntry,
    indexRow,
    newValue
  ) => {
    const updatedEntries = contractForm.unitRebates.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          rebateDataRows: entry.rebateDataRows.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                productCode: newValue
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleChangeProductDescription: (indexEntry: number, indexRow: number, description: string) => void = (
    indexEntry,
    indexRow,
    description
  ) => {
    const updatedEntries = contractForm.unitRebates.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          rebateDataRows: entry.rebateDataRows.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                productDescriptionEN: description
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const handleAddRow: (index: number) => void = (index) => {
    const updatedEntries = [...contractForm.unitRebates]
    const updatedEntry = { ...contractForm.unitRebates[index] }

    let newRow: IUnitRebateProductRow | IUnitRebateBrandRow | IUnitRebateAllSalesRow = {
      id: uuid(),
      distributionCenterIds: [],
      rebateMeasurementTypeId: null,
      amount: null
    }

    if (updatedEntry.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT) {
      newRow = {
        ...newRow,
        gtin: null,
        productDescriptionEN: null,
        productCode: null
      }
    } else if (updatedEntry.rebateTargetTypeId === ERebateTargetType.PRODUCT_BRAND) {
      newRow = {
        ...newRow,
        productBrandId: null
      }
    }

    const updatedRows = contractForm.unitRebates[index].rebateDataRows.length > 0 ? [...contractForm.unitRebates[index].rebateDataRows] : []
    updatedRows.push(newRow)

    updatedEntry.rebateDataRows = updatedRows

    updatedEntries[index] = updatedEntry
    save({ ...contractForm, unitRebates: updatedEntries })
  }

  const rowsData: Array<Array<Record<string, React.ReactNode>>> = contractForm.unitRebates.map((entry, index) => {
    const rows = Array.isArray(entry.rebateDataRows) ? entry.rebateDataRows : []
    return rows.map((row, indexRow) => ({
      distributionCenters: (
        <MultiSelect
          id={`distribution-centers-${index}-${indexRow}`}
          items={options.distributionCenterOptions}
          value={row.distributionCenterIds}
          error={validationErrors?.unitRebates?.[entry.id]?.rebateDataRows?.[row.id]?.distributionCenterIds ?? false}
          onChange={(newValues) => { handleDistributionCenterChange(index, indexRow, newValues) }}
      />
      ),
      measurementUnit: (
        <Select
          fullWidth
          id={`rebate-measurement-type-${index}-${indexRow}`}
          value={row.rebateMeasurementTypeId ?? ''}
          error={validationErrors?.unitRebates?.[entry.id]?.rebateDataRows?.[row.id]?.rebateMeasurementTypeId ?? false}
          onChange={(event: SelectChangeEvent<string>) => { handleMeasurementTypeChange(index, indexRow, event.target.value as EMeasurementType) }}
          MenuProps={selectMenuProps}
        >
          <MenuItem value={EMeasurementType.CASE}>{t('supplierContractForm.commons.measurementType.options.cases')}</MenuItem>
          <MenuItem value={EMeasurementType.PERCENTAGE}>{t('supplierContractForm.commons.measurementType.options.percentage')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_KG}>{t('supplierContractForm.commons.measurementType.options.dollarPerKg')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_G}>{t('supplierContractForm.commons.measurementType.options.dollarPerG')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_L}>{t('supplierContractForm.commons.measurementType.options.dollarPerL')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_ML}>{t('supplierContractForm.commons.measurementType.options.dollarPerMl')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_LBS}>{t('supplierContractForm.commons.measurementType.options.dollarPerLbs')}</MenuItem>
        </Select>
      ),
      rebateAmount: (
        <TextField
          id={`amount-${index}-${indexRow}`}
          type="number"
          value={row.amount === null ? '' : Number(row.amount).toString()}
          error={validationErrors?.unitRebates?.[entry.id]?.rebateDataRows?.[row.id]?.amount ?? false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = +event.target.value < 0 ? 0 : +event.target.value
            handleAmountChange(index, indexRow, newValue)
          }}
        />
      ),
      productBrand: entry.rebateTargetTypeId === ERebateTargetType.PRODUCT_BRAND
        ? (
            <Select
              fullWidth
              id={`brand-${index}-${indexRow}`}
              value={(row as IUnitRebateBrandRow).productBrandId ?? ''}
              onChange={(event: SelectChangeEvent<string>) => { handleBrandChange(index, indexRow, +event.target.value) }}
              error={validationErrors?.unitRebates?.[entry.id]?.rebateDataRows?.[row.id]?.productBrandId ?? false}
              MenuProps={selectMenuProps}
            >
              {options.brandOptions.map((item, index) => (
                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          )
        : null,
      GTIN: entry.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT
        ? (
            <Select
              fullWidth
              id={`gtin-${index}-${indexRow}`}
              value={(row as IUnitRebateProductRow).gtin ?? ''}
              onChange={(event: SelectChangeEvent<string>) => {
                handleGTINChange(index, indexRow, event.target.value)
              }}
              error={validationErrors?.unitRebates?.[entry.id]?.rebateDataRows?.[row.id]?.gtin ?? false}
              MenuProps={selectMenuProps}
            >
              {options.productOptions.map((item, index) => (
                <MenuItem key={index} value={item.id}>{item.id}</MenuItem>
              ))}
            </Select>
          )
        : null,
      productCode: entry.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT
        ? (
            <TextField
            id={`productCode-${index}-${indexRow}`}
            value={(row as IUnitRebateProductRow).productCode ?? ''}
            error={validationErrors?.unitRebates?.[entry.id]?.rebateDataRows?.[row.id]?.productCode ?? false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleProductCodeChange(index, indexRow, event.target.value)
            }}
            />
          )
        : null,
      description: entry.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT
        ? (
            <TextField
              id={`product-description-${index}-${indexRow}`}
              value={(row as IUnitRebateProductRow).productDescriptionEN ?? ''}
              error={validationErrors?.unitRebates?.[entry.id]?.rebateDataRows?.[row.id]?.productDescriptionEN ?? false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeProductDescription(index, indexRow, event.target.value)
              }}
            />
          )
        : null
    }))
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
        >
          {t('supplierContractForm.unitRebates.description')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel control={<Switch checked={contractForm.hasUnitRebate}/>} label={t('supplierContractForm.unitRebates.toggle.description')} onChange={handleChangeAddUnitRebate}/>
      </Grid>
      <Grid item xs={12}>
        {contractForm.unitRebates.map((entry, index) => (
          <Accordion key={entry.id} defaultExpanded sx={widgetProps}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" gutterBottom>{`${t('commons.rebate')} ${index + 1}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MultiSelect
                    required
                    label={t('supplierContractForm.commons.chooseFoodtasticBrands')}
                    items={options.foodtasticBrandOptions}
                    value={entry.foodtasticBrandIds}
                    error={validationErrors?.unitRebates?.[entry.id]?.foodtasticBrandIds ?? false}
                    helperText={validationErrors?.unitRebates?.[entry.id]?.foodtasticBrandIds as boolean ? t('commons.errors.validation.required') : ''}
                    onChange={(newValues) => { handleFoodtasticBrandsChange(newValues, index) }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl required error={validationErrors?.unitRebates?.[entry.id]?.rebateTargetTypeId ?? false}>
                    <FormLabel id="rebate-target" sx={{ mb: 1 }}>{t('supplierContractForm.unitRebates.rebates.sections.section1.rebateTarget.label')}</FormLabel>
                    <RadioGroup
                      aria-labelledby="rebate-target"
                      name="radio-buttons-group"
                      value={entry.rebateTargetTypeId}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleRebateTargetTypeChange(event, index) }}
                    >
                      <FormControlLabel value={ERebateTargetType.ALL_SALES} control={<Radio />} label={t('supplierContractForm.unitRebates.rebates.sections.section1.rebateTarget.options.allSales')} />
                      <FormControlLabel value={ERebateTargetType.PRODUCT_BRAND} control={<Radio />} label={t('supplierContractForm.unitRebates.rebates.sections.section1.rebateTarget.options.productBrand')} />
                      <FormControlLabel value={ERebateTargetType.SPECIFIC_PRODUCT} control={<Radio />} label={t('supplierContractForm.unitRebates.rebates.sections.section1.rebateTarget.options.specificProduct')} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>{t('supplierContractForm.unitRebates.rebates.sections.rebateCalculations.title')}</Typography>
                </Grid>
                { entry.rebateTargetTypeId === ERebateTargetType.ALL_SALES && rowsData[index].length > 0 && <Grid item xs={12}>
                  <SimpleTable
                    headers={allSalesHeaders}
                    rows={rowsData[index]}
                    columnWidths={['34%', '33%', '33%']}
                  />
                  </Grid>
                }
                { entry.rebateTargetTypeId === ERebateTargetType.PRODUCT_BRAND && rowsData[index].length > 0 && <Grid item xs={12}>
                  <SimpleTable
                    headers={specificBrandsHeaders}
                    rows={rowsData[index]}
                    columnWidths={['25%', '25%', '25%', '25%']}
                  />
                </Grid>}
                { entry.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT && rowsData[index].length > 0 && <Grid item xs={12}>
                  <SimpleTable
                    headers={specificProductsHeaders}
                    rows={rowsData[index]}
                    columnWidths={['200px', '200px', '200px', '200px', '200px', '200px']}
                  />
                </Grid>}
                { entry.rebateTargetTypeId !== ERebateTargetType.ALL_SALES &&
                  <Grid item xs={6}>
                    <Button variant="contained" color="primary" onClick={() => { handleAddRow(index) }}>
                      {t('supplierContractForm.unitRebates.buttons.addRow')}
                    </Button>
                  </Grid>
                }
                <Grid item xs={12}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button variant="contained" color="primary" onClick={() => { handleRemoveEntry(index) }}>
                    {t('supplierContractForm.unitRebates.buttons.removeRebate')}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      { contractForm.hasUnitRebate &&
        <>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleAddEntry}>
              {t('supplierContractForm.unitRebates.buttons.addRebate')}
            </Button>
          </Grid>
          { contractForm.unitRebates.length > 0 &&
            <Grid item xs={12}>
              <Accordion defaultExpanded sx={widgetProps}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="h6"
                    gutterBottom
                  >
                    {t('supplierContractForm.commons.paymentConditions.title')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel id="payment-frequency" sx={{ mb: 1 }}>{t('supplierContractForm.commons.paymentConditions.question')}</FormLabel>
                        <RadioGroup
                          aria-labelledby="payment-frequency"
                          name="radio-buttons-group"
                          value={contractForm.unitRebatesPaymentFrequencyId}
                          onChange={handlePaymentFrequencyChange}
                        >
                          <FormControlLabel value={EPaymentFrequency.MONTHLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.monthly')}/>
                          <FormControlLabel value={EPaymentFrequency.QUARTERLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.quarterly')}/>
                          <FormControlLabel value={EPaymentFrequency.BI_YEARLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.biyearly')}/>
                          <FormControlLabel value={EPaymentFrequency.YEARLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.yearly')}/>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t('supplierContractForm.commons.paymentConditions.question2')}
                        id="first-payment-issued-date"
                        type="date"
                        value={contractForm.unitRebatesFirstPaymentIssuedDate}
                        onChange={handleFirstPaymentDateChange}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          }
        </>
      }
    </Grid>
  )
}
