import React from 'react'
import { SupplierContractForm } from './SupplierContractForm'
import { useSupplierForm } from '../../stores/SupplierContractForm'
import { useTranslation } from 'react-i18next'
import { ErrorComponent } from '../errors'
import { Box, CircularProgress } from '@mui/material'
import { useOptions } from '../../stores/Options'

export const Contract: React.FC = () => {
  const { t } = useTranslation()
  const { state: supplierFormState } = useSupplierForm()
  const { state: options } = useOptions()
  const loading = supplierFormState.loading || options.loading
  const loadingError = supplierFormState.errorLoadingState || options.loadingError
  const contractId = supplierFormState.contractForm.contractId

  let content

  if (loading) {
    content = (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    )
  } else if (loadingError) {
    content = <ErrorComponent message={t('commons.errors.loadingContractForm', { id: contractId })}/>
  } else {
    content = <SupplierContractForm />
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {content}
    </Box>
  )
}
