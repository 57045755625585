import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, type SelectChangeEvent, Switch, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../stores/SupplierContractForm'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { type ISupplierContractFormAction, ESupplierContractFormActionType, type IVolumeRebate, ERebateTargetType, EMeasurementType, type IVolumeRebateThreshold, EPaymentFrequency, type IVolumeRebates } from '../../../types'
import { type IHeader, MultiSelect, SimpleTable } from '../../commons'
import { useOptions } from '../../../stores/Options'
import { v4 as uuid } from 'uuid'
import { CustomTextField as TextField } from '../../commons'
import { selectMenuProps, widgetProps } from '../../../utils'

export const VolumeRebates: React.FC = () => {
  const { state, dispatch } = useSupplierForm()
  const { state: options } = useOptions()
  const { t } = useTranslation()
  const contractForm = state.contractForm
  const validationErrors = state.contractFormErrors
  const specificProductOptions = options.productOptions.map(productOption => {
    const updatedProductOption = { ...productOption, name: productOption.id }
    return updatedProductOption
  })

  const save: (payload: IVolumeRebates) => void = payload => {
    const action: ISupplierContractFormAction = {
      type: ESupplierContractFormActionType.UPDATE_VOLUME_REBATES,
      payload
    }
    dispatch(action)
  }

  const handleAddEntry: () => void = () => {
    const newEntry: IVolumeRebate = {
      id: uuid(),
      foodtasticBrandIds: [],
      brandIds: [],
      productIds: [],
      rebateTargetTypeId: null,
      rebateMeasurementTypeId: null,
      thresholds: []
    }
    const newEntries = [...contractForm.volumeRebates, newEntry]
    save({ ...contractForm, volumeRebates: newEntries })
  }

  const handleRemoveEntry: (index: number) => void = (index) => {
    const updatedEntries = [...contractForm.volumeRebates]
    updatedEntries.splice(index, 1)
    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleFoodtasticBrandsChange: (newValues: string[], index: number) => void = (newValues, index) => {
    const updatedEntries = [...contractForm.volumeRebates]
    const updatedEntry = { ...contractForm.volumeRebates[index] }
    updatedEntry.foodtasticBrandIds = newValues
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleBrandsChange: (newValues: string[], index: number) => void = (newValues, index) => {
    const updatedEntries = [...contractForm.volumeRebates]
    const updatedEntry = { ...contractForm.volumeRebates[index] }
    updatedEntry.brandIds = newValues
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleProductsChange: (newValues: string[], index: number) => void = (newValues, index) => {
    const updatedEntries = [...contractForm.volumeRebates]
    const updatedEntry = { ...contractForm.volumeRebates[index] }
    updatedEntry.productIds = newValues
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleRebateTargetTypeChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void = (event, index) => {
    const updatedEntries = [...contractForm.volumeRebates]
    const updatedEntry = { ...contractForm.volumeRebates[index] }
    updatedEntry.rebateTargetTypeId = event.target.value as ERebateTargetType
    if (event.target.value !== ERebateTargetType.PRODUCT_BRAND) {
      updatedEntry.brandIds = []
    }
    if (event.target.value !== ERebateTargetType.SPECIFIC_PRODUCT) {
      updatedEntry.productIds = []
    }
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleRebateMeasurementTypeChange: (event: SelectChangeEvent<string>, index: number) => void = (event, index) => {
    const updatedEntries = [...contractForm.volumeRebates]
    const updatedEntry = { ...contractForm.volumeRebates[index] }
    updatedEntry.rebateMeasurementTypeId = event.target.value as EMeasurementType
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleChangeThresholdAmount: (indexRebate: number, indexThreshold: number, amount: number) => void = (
    indexRebate,
    indexThreshold,
    amount
  ) => {
    const updatedEntries = contractForm.volumeRebates.map((entry, i) => {
      if (i === indexRebate) {
        return {
          ...entry,
          thresholds: entry.thresholds.map((threshold, j) => {
            if (j === indexThreshold) {
              return {
                ...threshold,
                amount
              }
            }
            return threshold
          })
        }
      }
      return entry
    })

    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleChangeThresholdMin: (indexRebate: number, indexThreshold: number, min: number) => void = (
    indexRebate,
    indexThreshold,
    min
  ) => {
    const updatedEntries = contractForm.volumeRebates.map((entry, i) => {
      if (i === indexRebate) {
        return {
          ...entry,
          thresholds: entry.thresholds.map((threshold, j) => {
            if (j === indexThreshold) {
              return {
                ...threshold,
                min
              }
            }
            return threshold
          })
        }
      }
      return entry
    })

    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleChangeThresholdMax: (indexRebate: number, indexThreshold: number, max: number) => void = (
    indexRebate,
    indexThreshold,
    max
  ) => {
    const updatedEntries = contractForm.volumeRebates.map((entry, i) => {
      if (i === indexRebate) {
        return {
          ...entry,
          thresholds: entry.thresholds.map((threshold, j) => {
            if (j === indexThreshold) {
              return {
                ...threshold,
                max
              }
            }
            return threshold
          })
        }
      }
      return entry
    })

    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const headers: IHeader[] = [
    {
      key: 'thresholdMin',
      displayValue: t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.min')
    },
    {
      key: 'thresholdMax',
      displayValue: t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.max')
    },
    {
      key: 'measurementUnit',
      displayValue: t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.measurementUnit')
    },
    {
      key: 'rebateAmount',
      displayValue: t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.rebateThresholds.headers.rebateAmount')
    }
  ]

  const rowsData: Array<Array<Record<string, React.ReactNode>>> = contractForm.volumeRebates.map((entry, index) => {
    const thresholdsArray = Array.isArray(entry.thresholds) ? entry.thresholds : []
    return thresholdsArray.map((threshold, indexThreshold) => ({
      rebateAmount: (
        <TextField
          id={`threshold-amount-${index}-${indexThreshold}`}
          type="number"
          value={threshold.amount === null ? '' : Number(threshold.amount).toString()}
          error={validationErrors?.volumeRebates?.[entry.id]?.thresholds?.[threshold.id]?.amount ?? false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = +event.target.value < 0 ? 0 : +event.target.value
            handleChangeThresholdAmount(index, indexThreshold, newValue)
          }}
        />
      ),
      thresholdMin: (
        <TextField
          id={`threshold-min-${index}-${indexThreshold}`}
          type="number"
          value={threshold.min === null ? '' : Number(threshold.min).toString()}
          error={validationErrors?.volumeRebates?.[entry.id]?.thresholds?.[threshold.id]?.min ?? false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = +event.target.value < 0 ? 0 : +event.target.value
            handleChangeThresholdMin(index, indexThreshold, newValue)
          }}
        />
      ),
      thresholdMax: (
        <TextField
          id={`threshold-max-${index}-${indexThreshold}`}
          type="number"
          value={threshold.max === null ? '' : Number(threshold.max).toString()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = +event.target.value < 0 ? 0 : +event.target.value
            handleChangeThresholdMax(index, indexThreshold, newValue)
          }}
        />
      ),
      measurementUnit: (
        <Select
          fullWidth
          id="rebate-measurement-type"
          value={entry.rebateMeasurementTypeId ?? ''}
          error={validationErrors?.volumeRebates?.[entry.id]?.rebateMeasurementTypeId ?? false}
          onChange={(event: SelectChangeEvent<string>) => { handleRebateMeasurementTypeChange(event, index) }}
          MenuProps={selectMenuProps}
        >
          <MenuItem value={EMeasurementType.CASE}>{t('supplierContractForm.commons.measurementType.options.cases')}</MenuItem>
          <MenuItem value={EMeasurementType.DOLLARS}>{t('supplierContractForm.commons.measurementType.options.dollars')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_KG}>{t('supplierContractForm.commons.measurementType.options.kg')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_G}>{t('supplierContractForm.commons.measurementType.options.g')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_L}>{t('supplierContractForm.commons.measurementType.options.l')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_ML}>{t('supplierContractForm.commons.measurementType.options.ml')}</MenuItem>
          <MenuItem value={EMeasurementType.WEIGHT_LBS}>{t('supplierContractForm.commons.measurementType.options.lbs')}</MenuItem>
        </Select>
      )
    }))
  })

  const handleAddThresholdToRebate: (index: number) => void = (index) => {
    const updatedEntries = [...contractForm.volumeRebates]
    const updatedEntry = { ...contractForm.volumeRebates[index] }

    const newThreshold: IVolumeRebateThreshold = {
      id: uuid(),
      amount: null,
      min: null,
      max: null
    }

    const updatedThresholds = contractForm.volumeRebates[index].thresholds.length > 0 ? [...contractForm.volumeRebates[index].thresholds] : []
    updatedThresholds.push(newThreshold)

    updatedEntry.thresholds = updatedThresholds

    updatedEntries[index] = updatedEntry
    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleRemoveThresholdFromRebate: (rebateIndex: number, thresholdIndex: number) => void = (rebateIndex, thresholdIndex) => {
    const updatedEntries = [...contractForm.volumeRebates]
    const updatedEntry = { ...contractForm.volumeRebates[rebateIndex] }

    const updatedThresholds = [...contractForm.volumeRebates[rebateIndex].thresholds]
    updatedThresholds.splice(thresholdIndex, 1)
    updatedEntry.thresholds = updatedThresholds

    updatedEntries[rebateIndex] = updatedEntry

    save({ ...contractForm, volumeRebates: updatedEntries })
  }

  const handleChangeAddVolumeRebate: () => void = () => {
    const newValue = !contractForm.hasVolumeRebate
    if (!newValue) {
      save({
        ...contractForm,
        hasVolumeRebate: newValue
      })
    } else {
      save({ ...contractForm, hasVolumeRebate: newValue })
    }
  }

  const handleFirstPaymentDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newPaymentDate = event.target.value
    save({ ...contractForm, volumeRebatesFirstPaymentIssuedDate: newPaymentDate })
  }

  const handlePaymentFrequencyChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newPaymentFrequency = event.target.value as EPaymentFrequency
    save({ ...contractForm, volumeRebatesPaymentFrequencyId: newPaymentFrequency })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
        >
          {t('supplierContractForm.volumeRebates.description')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel control={<Switch checked={contractForm.hasVolumeRebate}/>} label={t('supplierContractForm.volumeRebates.toggle.description')} onChange={handleChangeAddVolumeRebate}/>
      </Grid>
      <Grid item xs={12}>
        {contractForm.volumeRebates.map((entry, index) => (
          <Accordion key={entry.id} defaultExpanded sx={widgetProps}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" gutterBottom>{`${t('commons.rebate')} ${index + 1}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MultiSelect
                    required
                    label={t('supplierContractForm.commons.chooseFoodtasticBrands')}
                    items={options.foodtasticBrandOptions}
                    value={entry.foodtasticBrandIds}
                    onChange={(newValues) => { handleFoodtasticBrandsChange(newValues, index) }}
                    error={validationErrors?.volumeRebates?.[entry.id]?.foodtasticBrandIds ?? false}
                    helperText={validationErrors?.volumeRebates?.[entry.id]?.foodtasticBrandIds as boolean ? t('commons.errors.validation.required') : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl error={validationErrors?.volumeRebates?.[entry.id]?.rebateTargetTypeId}>
                    <FormLabel id="rebate-target" required sx={{ mb: 1 }}>{t('supplierContractForm.volumeRebates.rebates.sections.section1.rebateTarget.label')}</FormLabel>
                    <RadioGroup
                      aria-labelledby="rebate-target"
                      name="radio-buttons-group"
                      value={entry.rebateTargetTypeId}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleRebateTargetTypeChange(event, index) }}
                    >
                      <FormControlLabel value={ERebateTargetType.ALL_SALES} control={<Radio />} label={t('supplierContractForm.volumeRebates.rebates.sections.section1.rebateTarget.options.allSales')} />
                      <FormControlLabel value={ERebateTargetType.PRODUCT_BRAND} control={<Radio />} label={t('supplierContractForm.volumeRebates.rebates.sections.section1.rebateTarget.options.productBrand')} />
                      <FormControlLabel value={ERebateTargetType.SPECIFIC_PRODUCT} control={<Radio />} label={t('supplierContractForm.volumeRebates.rebates.sections.section1.rebateTarget.options.specificProduct')} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                { entry.rebateTargetTypeId === ERebateTargetType.PRODUCT_BRAND && <Grid item xs={12}>
                  <MultiSelect
                    required
                    label={t('supplierContractForm.volumeRebates.rebates.sections.section1.chooseBrands')}
                    items={options.brandOptions}
                    value={entry.brandIds}
                    onChange={(newValues) => { handleBrandsChange(newValues, index) }}
                    error={validationErrors?.volumeRebates?.[entry.id]?.brandIds ?? false}
                    helperText={validationErrors?.volumeRebates?.[entry.id]?.brandIds as boolean ? t('commons.errors.validation.required') : ''}
                  />
                </Grid>}
                { entry.rebateTargetTypeId === ERebateTargetType.SPECIFIC_PRODUCT && <Grid item xs={12}>
                  <MultiSelect
                    required
                    label={t('supplierContractForm.volumeRebates.rebates.sections.section1.chooseProducts')}
                    items={specificProductOptions}
                    value={entry.productIds}
                    onChange={(newValues) => { handleProductsChange(newValues, index) }}
                    error={validationErrors?.volumeRebates?.[entry.id]?.productIds ?? false}
                    helperText={validationErrors?.volumeRebates?.[entry.id]?.productIds as boolean ? t('commons.errors.validation.required') : ''}
                  />
                </Grid>}
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>{t('supplierContractForm.volumeRebates.rebates.sections.rebateCalculations.title')}</Typography>
                </Grid>
                {rowsData[index].length > 0 && <Grid item xs={12}>
                  <SimpleTable
                    headers={headers}
                    columnWidths={['250px', '250px', '250px', '250px', '50px']}
                    rows={rowsData[index]}
                    onDeleteRow={(rowIndex) => { handleRemoveThresholdFromRebate(index, rowIndex) }}
                  />
                </Grid>}
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" onClick={() => { handleAddThresholdToRebate(index) }}>
                    {t('supplierContractForm.volumeRebates.buttons.addThreshold')}
                  </Button>
                </Grid>
                <Grid item xs={12}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button variant="contained" color="primary" onClick={() => { handleRemoveEntry(index) }}>
                    {t('supplierContractForm.volumeRebates.buttons.removeRebate')}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      { contractForm.hasVolumeRebate &&
        <>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleAddEntry}>
              {t('supplierContractForm.volumeRebates.buttons.addRebate')}
            </Button>
          </Grid>
          { contractForm.volumeRebates.length > 0 &&
            <Grid item xs={12}>
              <Accordion defaultExpanded sx={widgetProps}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="h6"
                    gutterBottom
                  >
                    {t('supplierContractForm.commons.paymentConditions.title')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel id="payment-frequency" sx={{ mb: 1 }}>{t('supplierContractForm.commons.paymentConditions.question')}</FormLabel>
                        <RadioGroup
                          aria-labelledby="payment-frequency"
                          name="radio-buttons-group"
                          value={contractForm.volumeRebatesPaymentFrequencyId}
                          onChange={handlePaymentFrequencyChange}
                        >
                          <FormControlLabel value={EPaymentFrequency.MONTHLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.monthly')}/>
                          <FormControlLabel value={EPaymentFrequency.QUARTERLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.quarterly')}/>
                          <FormControlLabel value={EPaymentFrequency.BI_YEARLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.biyearly')}/>
                          <FormControlLabel value={EPaymentFrequency.YEARLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.yearly')}/>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="first-payment-issued-date"
                        label={t('supplierContractForm.commons.paymentConditions.question2')}
                        type="date"
                        value={contractForm.volumeRebatesFirstPaymentIssuedDate}
                        onChange={handleFirstPaymentDateChange}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          }
        </>
      }
    </Grid>
  )
}
