import { type FilledInputProps, InputLabel, type InputProps, type OutlinedInputProps, TextField } from '@mui/material'
import React from 'react'

interface ICustomTextFieldProps {
  id: string
  label?: string
  type?: string
  InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps> | undefined
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
  name?: string
  placeholder?: string
  required?: boolean
}

export const CustomTextField: React.FC<ICustomTextFieldProps> = ({
  id,
  label,
  name,
  type,
  InputProps,
  value,
  onChange,
  error,
  helperText,
  placeholder,
  required = false
}): JSX.Element => {
  return (
  <>
    {label !== undefined && <InputLabel required={required} htmlFor={id} shrink={false} sx={{ mb: 1 }}>
      {label}
    </InputLabel>}
    <TextField
      fullWidth
      sx={{ background: 'white' }}
      id={id}
      name={name}
      type={type}
      InputProps={InputProps}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
    />
  </>
  )
}
