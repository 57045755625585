import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../stores/SupplierContractForm'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { EPaymentFrequency, type ISupplierContractFormAction, type ILumpSumRebate, ESupplierContractFormActionType, type ILumpSum } from '../../../types'
import { v4 as uuid } from 'uuid'
import { CustomTextField as TextField } from '../../commons'
import { widgetProps } from '../../../utils'

export const LumpSum: React.FC = () => {
  const { state, dispatch } = useSupplierForm()
  const { t } = useTranslation()
  const contractForm = state.contractForm
  const validationErrors = state.contractFormErrors

  const save: (payload: ILumpSum) => void = (payload) => {
    const action: ISupplierContractFormAction = {
      type: ESupplierContractFormActionType.UPDATE_LUMP_SUM,
      payload
    }
    dispatch(action)
  }

  const handleAddEntry: () => void = () => {
    const newEntry: ILumpSumRebate = {
      id: uuid(),
      lumpSumAmount: null,
      paymentFrequencyId: null,
      firstPaymentIssuedDate: '',
      firstPaymentAmount: null
    }
    const newEntries = [...contractForm.lumpSumRebates, newEntry]
    save({ ...contractForm, lumpSumRebates: newEntries })
  }

  const handleRemoveEntry: (index: number) => void = (index) => {
    const updatedEntries = [...contractForm.lumpSumRebates]
    updatedEntries.splice(index, 1)
    save({ ...contractForm, lumpSumRebates: updatedEntries })
  }

  const handleRebateAmountChange: (newAmount: number, index: number) => void = (newAmount, index) => {
    const updatedEntries = [...contractForm.lumpSumRebates]
    const updatedEntry = { ...contractForm.lumpSumRebates[index] }
    updatedEntry.lumpSumAmount = newAmount
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, lumpSumRebates: updatedEntries })
  }

  const handleFirstPaymentAmountChange: (newAmount: number, index: number) => void = (newAmount, index) => {
    const updatedEntries = [...contractForm.lumpSumRebates]
    const updatedEntry = { ...contractForm.lumpSumRebates[index] }
    updatedEntry.firstPaymentAmount = newAmount
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, lumpSumRebates: updatedEntries })
  }

  const handleFirstPaymentDateChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void = (event, index) => {
    const updatedEntries = [...contractForm.lumpSumRebates]
    const updatedEntry = { ...contractForm.lumpSumRebates[index] }
    updatedEntry.firstPaymentIssuedDate = event.target.value
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, lumpSumRebates: updatedEntries })
  }

  const handlePaymentFrequencyChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void = (event, index) => {
    const updatedEntries = [...contractForm.lumpSumRebates]
    const updatedEntry = { ...contractForm.lumpSumRebates[index] }
    updatedEntry.paymentFrequencyId = event.target.value as EPaymentFrequency
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, lumpSumRebates: updatedEntries })
  }

  const handleChangeAddLumpSum: () => void = () => {
    const newValue = !contractForm.hasLumpSumRebate
    if (!newValue) {
      save({ ...contractForm, hasLumpSumRebate: newValue, lumpSumRebates: [] })
    } else {
      save({ ...contractForm, hasLumpSumRebate: newValue })
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
        >
          {t('supplierContractForm.lumpSum.description')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel control={<Switch checked={contractForm.hasLumpSumRebate}/>} label={t('supplierContractForm.lumpSum.toggle.description')} onChange={handleChangeAddLumpSum}/>
      </Grid>
      <Grid item xs={12}>
        {contractForm.lumpSumRebates.map((entry, index) => (
          <Accordion key={entry.id} defaultExpanded sx={widgetProps}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" gutterBottom>{`${t('commons.rebate')} ${index + 1}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                  >
                    {t('supplierContractForm.lumpSum.rebates.sections.lumpSumAmount.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="lump-sum-amount"
                    label={t('supplierContractForm.lumpSum.rebates.sections.lumpSumAmount.question')}
                    type="number"
                    placeholder={t('supplierContractForm.lumpSum.rebates.sections.lumpSumAmount.placeholder')}
                    value={entry.lumpSumAmount === null ? '' : Number(entry.lumpSumAmount).toString()}
                    error={validationErrors?.lumpSumRebates?.[entry.id]?.lumpSumAmount ?? false}
                    helperText={validationErrors?.lumpSumRebates?.[entry.id]?.lumpSumAmount as boolean ? t('commons.errors.validation.required') : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newValue = +event.target.value < 0 ? 0 : +event.target.value
                      handleRebateAmountChange(newValue, index)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                  >
                    {t('supplierContractForm.commons.paymentConditions.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="payment-frequency" sx={{ mb: 1 }}>{t('supplierContractForm.commons.paymentConditions.questionAlt')}</FormLabel>
                    <RadioGroup
                      aria-labelledby="payment-frequency"
                      name="radio-buttons-group"
                      value={entry.paymentFrequencyId}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handlePaymentFrequencyChange(event, index) }}
                    >
                      <FormControlLabel value={EPaymentFrequency.MONTHLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.monthly')}/>
                      <FormControlLabel value={EPaymentFrequency.QUARTERLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.quarterly')}/>
                      <FormControlLabel value={EPaymentFrequency.BI_YEARLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.biyearly')}/>
                      <FormControlLabel value={EPaymentFrequency.YEARLY} control={<Radio />} label={t('supplierContractForm.commons.paymentConditions.frequencyOptions.yearly')}/>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="first-payment-issued-date"
                    label={t('supplierContractForm.commons.paymentConditions.question2')}
                    type="date"
                    value={entry.firstPaymentIssuedDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleFirstPaymentDateChange(event, index) }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="first-payment-amount"
                    label={t('supplierContractForm.commons.paymentConditions.question3')}
                    type="number"
                    placeholder={t('supplierContractForm.lumpSum.rebates.sections.lumpSumAmount.placeholder')}
                    value={entry.firstPaymentAmount === null ? '' : Number(entry.firstPaymentAmount).toString()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newValue = +event.target.value < 0 ? 0 : +event.target.value
                      handleFirstPaymentAmountChange(newValue, index)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  >
                  <Button variant="contained" color="primary" onClick={() => { handleRemoveEntry(index) }}>
                    {t('supplierContractForm.lumpSum.buttons.removeRebate')}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      { contractForm.hasLumpSumRebate && <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleAddEntry}>
          {t('supplierContractForm.lumpSum.buttons.addRebate')}
        </Button>
      </Grid>}
    </Grid>
  )
}
