import { Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, Grid, MenuItem, Select, type SelectChangeEvent, Switch, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../stores/SupplierContractForm'
import { ESupplierContractFormActionType, type IProductDataRow, type IContractedPrice, type ISupplierContractFormAction, EDeliveryMethods, type IContractedPrices } from '../../../types'
import { type IHeader, MultiSelect, SimpleTable } from '../../commons'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useOptions } from '../../../stores/Options'
import { v4 as uuid } from 'uuid'
import { selectMenuProps, widgetProps } from '../../../utils'

export const ContractedPrices: React.FC = (props) => {
  const { state, dispatch } = useSupplierForm()
  const { state: options } = useOptions()
  const { t } = useTranslation()
  const contractForm = state.contractForm
  const validationErrors = state.contractFormErrors

  const save: (payload: IContractedPrices) => void = (payload) => {
    const action: ISupplierContractFormAction = {
      type: ESupplierContractFormActionType.UPDATED_CONTRACTED_PRICES,
      payload
    }
    dispatch(action)
  }

  const handleChangeAddContractedPrices: () => void = () => {
    const newValue = !contractForm.hasContractedPrice
    if (!newValue) {
      save({ ...contractForm, hasContractedPrice: newValue, contractedPrices: [] })
    } else {
      save({ ...contractForm, hasContractedPrice: newValue })
    }
  }

  const handleAddEntry: () => void = () => {
    const newEntry: IContractedPrice = {
      id: uuid(),
      foodtasticBrandIds: [],
      contractedPriceData: options.productRows.map((product) => {
        return {
          ...product,
          dateInEffect: contractForm.contractStartDate,
          expirationDate: contractForm.contractEndDate
        }
      })
    }
    const newEntries = [...contractForm.contractedPrices, newEntry]
    save({ ...contractForm, contractedPrices: newEntries })
  }

  const handleRemoveEntry: (index: number) => void = (index) => {
    const updatedEntries = [...contractForm.contractedPrices]
    updatedEntries.splice(index, 1)
    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleFoodtasticBrandsChange: (newValues: string[], index: number) => void = (newValues, index) => {
    const updatedEntries = [...contractForm.contractedPrices]
    const updatedEntry = { ...contractForm.contractedPrices[index] }
    updatedEntry.foodtasticBrandIds = newValues
    updatedEntries[index] = updatedEntry
    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleAddRowData: (index: number) => void = (index) => {
    const updatedEntries = [...contractForm.contractedPrices]
    const updatedEntry = { ...contractForm.contractedPrices[index] }

    const newRow: IProductDataRow = {
      id: uuid(),
      productGTIN: '',
      productCode: null,
      productDescriptionEN: null,
      minimumVolume: null,
      contractedPrice: null,
      distributionCenterIds: [],
      dateInEffect: contractForm.contractStartDate,
      expirationDate: contractForm.contractEndDate,
      deliveryMethodId: null
    }

    const updatedRows = contractForm.contractedPrices[index].contractedPriceData.length > 0 ? [...contractForm.contractedPrices[index].contractedPriceData] : []
    updatedRows.push(newRow)

    updatedEntry.contractedPriceData = updatedRows

    updatedEntries[index] = updatedEntry
    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleRemoveRowData: (entryIndex: number, rowIndex: number) => void = (entryIndex, rowIndex) => {
    const updatedEntries = [...contractForm.contractedPrices]
    const updatedEntry = { ...contractForm.contractedPrices[entryIndex] }

    const updatedRows = contractForm.contractedPrices[entryIndex].contractedPriceData.length > 0 ? [...contractForm.contractedPrices[entryIndex].contractedPriceData] : []
    updatedRows.splice(rowIndex, 1)
    updatedEntry.contractedPriceData = updatedRows

    updatedEntries[entryIndex] = updatedEntry
    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleCloneRowData: (entryIndex: number, rowIndex: number) => void = (entryIndex, rowIndex) => {
    const updatedEntries = [...contractForm.contractedPrices]
    const updatedEntry = { ...contractForm.contractedPrices[entryIndex] }
    const clonedRow = { ...updatedEntry.contractedPriceData[rowIndex] }

    const updatedRows = contractForm.contractedPrices[entryIndex].contractedPriceData.length > 0 ? [...contractForm.contractedPrices[entryIndex].contractedPriceData] : []
    updatedRows.splice(rowIndex, 0, clonedRow)
    updatedEntry.contractedPriceData = updatedRows

    updatedEntries[entryIndex] = updatedEntry
    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleChangeGTIN: (indexEntry: number, indexRow: number, gtin: string) => void = (
    indexEntry,
    indexRow,
    gtin
  ) => {
    const updatedEntries = contractForm.contractedPrices.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          contractedPriceData: entry.contractedPriceData.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                productGTIN: gtin
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleChangeProductCode: (indexEntry: number, indexRow: number, code: string) => void = (
    indexEntry,
    indexRow,
    code
  ) => {
    const updatedEntries = contractForm.contractedPrices.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          contractedPriceData: entry.contractedPriceData.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                productCode: code
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleChangeProductDescription: (indexEntry: number, indexRow: number, description: string) => void = (
    indexEntry,
    indexRow,
    description
  ) => {
    const updatedEntries = contractForm.contractedPrices.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          contractedPriceData: entry.contractedPriceData.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                productDescriptionEN: description
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleChangeMinimumVolume: (indexEntry: number, indexRow: number, volume: number) => void = (
    indexEntry,
    indexRow,
    volume
  ) => {
    const updatedEntries = contractForm.contractedPrices.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          contractedPriceData: entry.contractedPriceData.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                minimumVolume: volume
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleChangeRowPrice: (indexEntry: number, indexRow: number, price: number) => void = (
    indexEntry,
    indexRow,
    price
  ) => {
    const updatedEntries = contractForm.contractedPrices.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          contractedPriceData: entry.contractedPriceData.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                contractedPrice: price
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleDistributionCenterChanges: (indexEntry: number, indexRow: number, newValues: string[]) => void = (
    indexEntry,
    indexRow,
    newValues
  ) => {
    const updatedEntries = contractForm.contractedPrices.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          contractedPriceData: entry.contractedPriceData.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                distributionCenterIds: newValues
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleChangeStartDate: (indexEntry: number, indexRow: number, newDate: string) => void = (
    indexEntry,
    indexRow,
    newDate
  ) => {
    const updatedEntries = contractForm.contractedPrices.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          contractedPriceData: entry.contractedPriceData.map((row, j) => {
            if (j === indexRow) {
              if (row.dateInEffect > row.expirationDate) {
                return {
                  ...row,
                  dateInEffect: newDate,
                  expirationDate: ''
                }
              } else {
                return {
                  ...row,
                  dateInEffect: newDate
                }
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleChangeEndDate: (indexEntry: number, indexRow: number, newDate: string) => void = (
    indexEntry,
    indexRow,
    newDate
  ) => {
    const updatedEntries = contractForm.contractedPrices.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          contractedPriceData: entry.contractedPriceData.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                expirationDate: newDate
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const handleChangeDeliveryMethod: (indexEntry: number, indexRow: number, deliveryMethodId: string) => void = (
    indexEntry,
    indexRow,
    deliveryMethodId
  ) => {
    const updatedEntries = contractForm.contractedPrices.map((entry, i) => {
      if (i === indexEntry) {
        return {
          ...entry,
          contractedPriceData: entry.contractedPriceData.map((row, j) => {
            if (j === indexRow) {
              return {
                ...row,
                deliveryMethodId
              }
            }
            return row
          })
        }
      }
      return entry
    })

    save({ ...contractForm, contractedPrices: updatedEntries })
  }

  const headers: IHeader[] = [
    {
      key: 'productGTIN',
      displayValue: `${t('supplierContractForm.contractedPrices.section.headers.productGTIN')}`
    },
    {
      key: 'productCode',
      displayValue: `${t('supplierContractForm.contractedPrices.section.headers.productCode')}`
    },
    {
      key: 'productDescription',
      displayValue: `${t('supplierContractForm.contractedPrices.section.headers.productDescription')}`
    },
    {
      key: 'minimumVolume',
      displayValue: `${t('supplierContractForm.contractedPrices.section.headers.minimumVolume')}`
    },
    {
      key: 'contractedPrice',
      displayValue: `${t('supplierContractForm.contractedPrices.section.headers.contractedPrice')}`
    },
    {
      key: 'distributorCenters',
      displayValue: `${t('supplierContractForm.contractedPrices.section.headers.distributorCenters')}`
    },
    {
      key: 'startDate',
      displayValue: `${t('supplierContractForm.contractedPrices.section.headers.startDate')}`
    },
    {
      key: 'expirationDate',
      displayValue: `${t('supplierContractForm.contractedPrices.section.headers.expirationDate')}`
    },
    {
      key: 'deliveryMethod',
      displayValue: `${t('supplierContractForm.contractedPrices.section.headers.deliveryMethod')}`
    }
  ]

  const rowsData: Array<Array<Record<string, React.ReactNode>>> = contractForm.contractedPrices.map((entry, index) => {
    const rows = Array.isArray(entry.contractedPriceData) ? entry.contractedPriceData : []
    return rows.map((row, indexRow) => {
      return {
        productGTIN: (
          <TextField
            fullWidth
            id={`product-gtin-${index}-${indexRow}`}
            variant="outlined"
            value={row.productGTIN ?? ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeGTIN(index, indexRow, event.target.value)
            }}
          />
        ),
        productCode: (
          <TextField
            fullWidth
            id={`product-code-${index}-${indexRow}`}
            variant="outlined"
            value={row.productCode ?? ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeProductCode(index, indexRow, event.target.value)
            }}
          />
        ),
        productDescription: (
          <TextField
            fullWidth
            id={`product-description-${index}-${indexRow}`}
            variant="outlined"
            value={row.productDescriptionEN}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeProductDescription(index, indexRow, event.target.value)
            }}
          />
        ),
        minimumVolume: (
          <TextField
            fullWidth
            id={`minimum-volume-${index}-${indexRow}`}
            variant="outlined"
            type="number"
            value={row.minimumVolume === null ? '' : Number(row.minimumVolume).toString()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = +event.target.value < 0 ? 0 : +event.target.value
              handleChangeMinimumVolume(index, indexRow, newValue)
            }}
          />
        ),
        contractedPrice: (
          <TextField
            fullWidth
            id={`contracted-price-${index}-${indexRow}`}
            variant="outlined"
            type="number"
            value={row.contractedPrice === null ? '' : Number(row.contractedPrice).toString()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = +event.target.value < 0 ? 0 : +event.target.value
              handleChangeRowPrice(index, indexRow, newValue)
            }}
          />
        ),
        distributorCenters: (
          <MultiSelect
            id={`distributor-centers-${index}-${indexRow}`}
            items={options.distributionCenterOptions}
            value={row.distributionCenterIds}
            onChange={(newValues) => { handleDistributionCenterChanges(index, indexRow, newValues) }}
          />
        ),
        startDate: (
          <TextField
            fullWidth
            id={`start-date-${index}-${indexRow}`}
            type="date"
            value={row.dateInEffect}
            error={validationErrors.contractedPrices?.[entry.id]?.rowsErrors?.[row.id]?.dateInEffect}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeStartDate(index, indexRow, event.target.value) }}
          />
        ),
        expirationDate: (
          <TextField
            fullWidth
            id={`end-date-${index}-${indexRow}`}
            type="date"
            InputProps={{
              inputProps: { min: contractForm.contractStartDate }
            }}
            value={row.expirationDate}
            error={validationErrors.contractedPrices?.[entry.id]?.rowsErrors?.[row.id]?.expirationDate}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeEndDate(index, indexRow, event.target.value) }}
          />
        ),
        deliveryMethod: (
          <Select
            fullWidth
            id="delivery-method"
            value={row.deliveryMethodId ?? ''}
            onChange={(event: SelectChangeEvent<string>) => { handleChangeDeliveryMethod(index, indexRow, event.target.value) }}
            MenuProps={selectMenuProps}
          >
            <MenuItem value={EDeliveryMethods.DISTRIBUTOR}>{`${t('supplierContractForm.contractedPrices.section.tableOptions.deliveryMethod1')}`}</MenuItem>
            <MenuItem value={EDeliveryMethods.PICK_UP}>{`${t('supplierContractForm.contractedPrices.section.tableOptions.deliveryMethod2')}`}</MenuItem>
          </Select>
        )
      }
    })
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
        >
          {t('supplierContractForm.contractedPrices.description')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel control={<Switch checked={contractForm.hasContractedPrice}/>} label={t('supplierContractForm.contractedPrices.toggle.description')} onChange={handleChangeAddContractedPrices}/>
      </Grid>
      <Grid item xs={12}>
        {contractForm.contractedPrices.map((entry, index) => (
          <Accordion key={entry.id} defaultExpanded sx={widgetProps}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" gutterBottom>{`${t('supplierContractForm.contractedPrices.section.title')} ${index + 1}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MultiSelect
                    required
                    label={t('supplierContractForm.commons.chooseFoodtasticBrands')}
                    items={options.foodtasticBrandOptions}
                    value={entry.foodtasticBrandIds}
                    onChange={(newValues) => { handleFoodtasticBrandsChange(newValues, index) }}
                    error={validationErrors.contractedPrices?.[entry.id]?.foodtasticBrandIds}
                    helperText={validationErrors.contractedPrices?.[entry.id]?.foodtasticBrandIds as boolean ? t('commons.errors.validation.expiration') : ''}
                  />
                </Grid>
                {rowsData[index].length > 0 &&
                  <Grid item xs={12}>
                    <SimpleTable
                      headers={headers}
                      columnWidths={['200px', '200px', '300px', '200px', '200px', '200px', '200px', '200px', '200px', '50px']}
                      rows={rowsData[index]}
                      onDeleteRow={(rowIndex) => { handleRemoveRowData(index, rowIndex) }}
                      onCloneRow={(rowIndex) => { handleCloneRowData(index, rowIndex) }}
                    />
                  </Grid>
                }
                <Grid item xs={12}
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <Button variant="contained" color="primary" onClick={() => { handleAddRowData(index) }}>
                    {t('supplierContractForm.contractedPrices.buttons.addProduct')}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { handleRemoveEntry(index) }}>
                    {t('supplierContractForm.contractedPrices.buttons.removePrices')}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      { contractForm.hasContractedPrice && <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleAddEntry}>
          {t('supplierContractForm.contractedPrices.buttons.addPrices')}
        </Button>
      </Grid>}
    </Grid>
  )
}
