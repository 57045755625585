import React from 'react'
import { useSupplierForm } from '../../../stores/SupplierContractForm'
import { ESupplierContractFormActionType, type IContractInformation, type ISupplierContractFormAction } from '../../../types'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { CustomTextField as TextField } from '../../commons'
import { widgetProps } from '../../../utils'

export const ContractInformation: React.FC = (props) => {
  const { state, dispatch } = useSupplierForm()
  const { t } = useTranslation()
  const contractForm = state.contractForm
  const validationErrors = state.contractFormErrors

  const handleSupplierContactFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierFirstName: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, firstName: newSupplierFirstName } })
  }

  const handleSupplierContactLastNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierLastName: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, lastName: newSupplierLastName } })
  }

  const handleSupplierContactMiddleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierMiddleName: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, middleName: newSupplierMiddleName } })
  }

  const handleSupplierContactPhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierPhone: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, phone: newSupplierPhone } })
  }

  const handleSupplierContactPhoneExtChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierPhoneExt: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, phoneExt: newSupplierPhoneExt } })
  }

  const handleSupplierContactEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierEmail: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, email: newSupplierEmail } })
  }

  const handleSupplierContactAddressChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierAddress: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, address: newSupplierAddress } })
  }

  const handleSupplierContactCityChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierCity: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, city: newSupplierCity } })
  }

  const handleSupplierContactProvinceChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierProvince: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, province: newSupplierProvince } })
  }

  const handleSupplierContactZipCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newSupplierZipCode: string = event.target.value
    save({ ...contractForm, supplierContact: { ...contractForm.supplierContact, zipCode: newSupplierZipCode } })
  }

  const handleContactFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newContactFirstName: string = event.target.value
    save({ ...contractForm, contact: { ...contractForm.contact, firstName: newContactFirstName } })
  }

  const handleContactLastNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newContactLastName: string = event.target.value
    save({ ...contractForm, contact: { ...contractForm.contact, lastName: newContactLastName } })
  }

  const handleContactMiddleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newContactMiddleName: string = event.target.value
    save({ ...contractForm, contact: { ...contractForm.contact, middleName: newContactMiddleName } })
  }

  const handleContactPhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newContactPhone: string = event.target.value
    save({ ...contractForm, contact: { ...contractForm.contact, phone: newContactPhone } })
  }

  const handleContactPhoneExtChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newContactPhoneExt: string = event.target.value
    save({ ...contractForm, contact: { ...contractForm.contact, phoneExt: newContactPhoneExt } })
  }

  const handleContactEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newContactEmail: string = event.target.value
    save({ ...contractForm, contact: { ...contractForm.contact, email: newContactEmail } })
  }

  const handleStartDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newStartDate: string = event.target.value
    save({ ...contractForm, contractStartDate: newStartDate })
  }

  const handleEndDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newEndDate: string = event.target.value
    save({ ...contractForm, contractEndDate: newEndDate })
  }

  const handleHasLumpSumRebateChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newValue: boolean = event.target.checked
    save({ ...contractForm, hasLumpSumRebate: newValue })
  }

  const handleHasContractedPriceChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newValue: boolean = event.target.checked
    save({ ...contractForm, hasContractedPrice: newValue })
  }

  const handleHasVolumeRebateChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newValue: boolean = event.target.checked
    save({ ...contractForm, hasVolumeRebate: newValue })
  }

  const handleHasUnitRebateChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
    const newValue: boolean = event.target.checked
    save({ ...contractForm, hasUnitRebate: newValue })
  }

  const save: (payload: IContractInformation) => void = (payload) => {
    const action: ISupplierContractFormAction = {
      type: ESupplierContractFormActionType.UPDATE_CONTRACT_INFORMATION,
      payload
    }
    dispatch(action)
  }

  return (
    <Box
      sx={widgetProps}
      padding={2}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            gutterBottom
          >
            {t('supplierContractForm.contractInformation.supplierContact.title')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="supplierContactFirstName"
            label={t('supplierContractForm.contractInformation.supplierContact.firstName')}
            name="supplierContactFirstName"
            value={contractForm.supplierContact.firstName}
            onChange={handleSupplierContactFirstNameChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t('supplierContractForm.contractInformation.supplierContact.middleName')}
            id="supplierContactMiddleName"
            name="supplierContactMiddleName"
            value={contractForm.supplierContact.middleName}
            onChange={handleSupplierContactMiddleNameChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t('supplierContractForm.contractInformation.supplierContact.lastName')}
            id="supplierContactLastName"
            name="supplierContactLastName"
            value={contractForm.supplierContact.lastName}
            onChange={handleSupplierContactLastNameChange}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label={t('supplierContractForm.contractInformation.supplierContact.phoneNumber')}
            id="supplierContactPhone"
            name="supplierContactPhone"
            value={contractForm.supplierContact.phone}
            onChange={handleSupplierContactPhoneChange}
            error={validationErrors.supplierContact.phone}
            helperText={validationErrors.supplierContact.phone ? t('commons.errors.validation.invalidPhone') : ''}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t('supplierContractForm.contractInformation.supplierContact.phoneExt')}
            id="supplierContactPhoneExt"
            name="supplierContactPhoneExt"
            value={contractForm.supplierContact.phoneExt}
            onChange={handleSupplierContactPhoneExtChange}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label={t('supplierContractForm.contractInformation.supplierContact.email')}
            id="supplierContactEmail"
            name="supplierContactEmail"
            value={contractForm.supplierContact.email}
            onChange={handleSupplierContactEmailChange}
            error={validationErrors.supplierContact.email}
            helperText={validationErrors.supplierContact.email ? t('commons.errors.validation.invalidEmail') : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('supplierContractForm.contractInformation.supplierContact.address')}
            id="supplierContactAddress"
            name="supplierContactAddress"
            value={contractForm.supplierContact.address}
            onChange={handleSupplierContactAddressChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('supplierContractForm.contractInformation.supplierContact.city')}
            id="supplierContactCity"
            name="supplierContactCity"
            value={contractForm.supplierContact.city}
            onChange={handleSupplierContactCityChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t('supplierContractForm.contractInformation.supplierContact.provinceOrState')}
            id="supplierContactProvince"
            name="supplierContactProvince"
            value={contractForm.supplierContact.province}
            onChange={handleSupplierContactProvinceChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t('supplierContractForm.contractInformation.supplierContact.zip')}
            id="supplierContactZipCode"
            name="supplierContactZipCode"
            value={contractForm.supplierContact.zipCode}
            onChange={handleSupplierContactZipCodeChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
              variant="h5"
              gutterBottom
              sx={{ marginTop: 1 }}
            >
              {t('supplierContractForm.contractInformation.companyContact.title')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="contactFirstName"
            label={t('supplierContractForm.contractInformation.companyContact.firstName')}
            name="contactFirstName"
            value={contractForm.contact.firstName}
            onChange={handleContactFirstNameChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="contactMiddleName"
            label={t('supplierContractForm.contractInformation.companyContact.middleName')}
            name="contactMiddleName"
            value={contractForm.contact.middleName}
            onChange={handleContactMiddleNameChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="contactLastName"
            label={t('supplierContractForm.contractInformation.companyContact.lastName')}
            name="contactLastName"
            value={contractForm.contact.lastName}
            onChange={handleContactLastNameChange}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            id="contactPhone"
            label={t('supplierContractForm.contractInformation.companyContact.phoneNumber')}
            name="contactPhone"
            value={contractForm.contact.phone}
            onChange={handleContactPhoneChange}
            error={validationErrors.contact.phone}
            helperText={validationErrors.contact.phone ? t('commons.errors.validation.invalidPhone') : ''}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="contactPhoneExt"
            label={t('supplierContractForm.contractInformation.companyContact.phoneExt')}
            name="contactPhoneExt"
            value={contractForm.contact.phoneExt}
            onChange={handleContactPhoneExtChange}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            id="contactEmail"
            label={t('supplierContractForm.contractInformation.companyContact.email')}
            name="contactEmail"
            value={contractForm.contact.email}
            onChange={handleContactEmailChange}
            error={validationErrors.contact.email}
            helperText={validationErrors.contact.email ? t('commons.errors.validation.invalidEmail') : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
              variant="h5"
              gutterBottom
              sx={{ marginTop: 1 }}
            >
              {t('supplierContractForm.contractInformation.contractDates.title')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="start-date"
            label={t('supplierContractForm.contractInformation.contractDates.startDate')}
            type="date"
            value={contractForm.contractStartDate}
            onChange={handleStartDateChange}
            error={validationErrors.contractStartDate}
            helperText={validationErrors.contractStartDate ? t('commons.errors.validation.required') : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="end-date"
            label={t('supplierContractForm.contractInformation.contractDates.endDate')}
            type="date"
            InputProps={{
              inputProps: { min: contractForm.contractStartDate }
            }}
            value={contractForm.contractEndDate}
            onChange={handleEndDateChange}
            error={validationErrors.contractEndDate}
            helperText={validationErrors.contractEndDate ? t('commons.errors.validation.endDate') : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
              variant="h5"
              gutterBottom
              sx={{ marginTop: 1 }}
            >
              {t('supplierContractForm.contractInformation.rebates.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
                variant="h6"
                gutterBottom
              >
                {t('supplierContractForm.contractInformation.rebates.subtitle')}
            </Typography>
            <FormGroup>
              <FormControlLabel
                label={
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography >
                      {t('supplierContractForm.contractInformation.rebates.checkbox.label.lumpSum')}
                    </Typography>
                    <Tooltip title={t('supplierContractForm.lumpSum.description')} placement="right-end">
                      <InfoIcon fontSize="small" style={{ marginLeft: 4 }} />
                    </Tooltip>
                  </Box>
                }
                control={<Checkbox checked={contractForm.hasLumpSumRebate} onChange={handleHasLumpSumRebateChange} />}
              />
              <FormControlLabel
                label={
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      {t('supplierContractForm.contractInformation.rebates.checkbox.label.contractedPrices')}
                    </Typography>
                    <Tooltip title={t('supplierContractForm.contractedPrices.description')} placement="right-end">
                      <InfoIcon fontSize="small" style={{ marginLeft: 4 }} />
                    </Tooltip>
                  </Box>
                }
                control={<Checkbox checked={contractForm.hasContractedPrice} onChange={handleHasContractedPriceChange} />}
              />
              <FormControlLabel
                label={
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      {t('supplierContractForm.contractInformation.rebates.checkbox.label.volumeRebates')}
                    </Typography>
                    <Tooltip title={t('supplierContractForm.volumeRebates.description')} placement="right-end">
                      <InfoIcon fontSize="small" style={{ marginLeft: 4 }} />
                    </Tooltip>
                  </Box>
                }
                control={<Checkbox checked={contractForm.hasVolumeRebate} onChange={handleHasVolumeRebateChange} />}
              />
              <FormControlLabel
                label={
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      {t('supplierContractForm.contractInformation.rebates.checkbox.label.unitRebates')}
                    </Typography>
                    <Tooltip title={t('supplierContractForm.unitRebates.description')} placement="right-end">
                      <InfoIcon fontSize="small" style={{ marginLeft: 4 }} />
                    </Tooltip>
                  </Box>
                }
                control={<Checkbox checked={contractForm.hasUnitRebate} onChange={handleHasUnitRebateChange} />}
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
