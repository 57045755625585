import { EContractsListActionType, type IContractsListAction, type IContractsListState } from '../types'

export const contractsListReducer: (state: IContractsListState, action: IContractsListAction) => IContractsListState = (state, action) => {
  switch (action.type) {
    case EContractsListActionType.SET_LIST_DATA:
      return {
        ...state,
        contracts: action.payload.contracts,
        suppliers: action.payload.suppliers,
        loading: false
      }
    default:
      return state
  }
}
