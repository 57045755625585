import React, { type ReactNode, createContext, useContext, useReducer, useEffect } from 'react'
import { type IOptionsMenuState, type IOptionsMenuContext, type IOption, type IProduct, EOptionsMenuStateType, type IProductDataRow, EDeliveryMethods, EPaymentFrequency, EMeasurementType } from '../types'
import { optionsReducer } from '../reducers'
import { getDistributors, getFoodtasticBrands, getProductsBySupplierId, getSupplierBrandsBySupplierId } from '../components'
import { compareGTIN, compareOptionNames } from '../utils'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MenuOptionsInitialState: IOptionsMenuState = {
  brandOptions: [],
  foodtasticBrandOptions: [],
  distributionCenterOptions: [],
  productRows: [],
  productOptions: [],
  loading: true,
  deliveryMethodOptions: [],
  paymentFrequencyOptions: [],
  measurementTypeOptionsVolumeRebate: [],
  measurementTypeOptionsUnitRebate: [],
  loadingError: false
}

const defaultContextValue: IOptionsMenuContext = {
  state: MenuOptionsInitialState,
  dispatch: (action) => {
    console.log('Dispatching action:', action)
  }
}

const OptionsContext = createContext<IOptionsMenuContext>(defaultContextValue)

interface IOptionsProviderProps {
  children: ReactNode
}

export const OptionsProvider: React.FC<IOptionsProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(optionsReducer, MenuOptionsInitialState)
  const { supplierId } = useParams()
  const { t } = useTranslation()

  const getFoodtasticBrandOptions: () => Promise<IOption[]> = async () => {
    const brands = await getFoodtasticBrands()
    const options: IOption[] = brands.map((brand) => {
      return {
        id: brand.BannerId,
        name: brand.BannerName
      }
    })
    options.sort(compareOptionNames)

    return options
  }

  const getDistributorOptions: () => Promise<IOption[]> = async () => {
    const distributors = await getDistributors()
    const options: IOption[] = distributors.map((distributor) => {
      return {
        id: distributor.DistributorId,
        name: distributor.DistributorName
      }
    })

    options.sort(compareOptionNames)
    return options
  }

  const getBrandOptions: (supplierId: string | undefined) => Promise<IOption[]> = async () => {
    let options: IOption[] = []
    if (supplierId !== undefined) {
      const brands = await getSupplierBrandsBySupplierId(supplierId)
      options = brands.map((brand) => {
        return {
          id: brand.ProductBrandId,
          name: brand.ProductBrandName
        }
      })
      options.sort(compareOptionNames)
    }
    return options
  }

  const getProducts: (supplierId: string | undefined) => Promise<IProduct[]> = async () => {
    let products: IProduct[] = []

    if (supplierId !== undefined) {
      products = await getProductsBySupplierId(supplierId)
    }
    return products
  }

  const getProductRowsAndOptions: (products: IProduct[]) => Promise<{ productRows: IProductDataRow[], productOptions: IOption[] }> = async (products) => {
    const productRows: IProductDataRow[] = products.map((product) => {
      return {
        id: product.GTIN,
        productGTIN: product.GTIN,
        productCode: product.SupplierProductCode,
        productDescriptionEN: `${product.DescriptionEN}, ${product.ItemSize}, ${product.PackSize}`,
        minimumVolume: null,
        contractedPrice: null,
        distributionCenterIds: [],
        dateInEffect: '',
        expirationDate: '',
        deliveryMethodId: null
      }
    })

    const productOptions: IOption[] = products.map((product) => {
      return {
        id: product.GTIN,
        name: product.DescriptionEN
      }
    })

    productRows.sort(compareGTIN)
    productOptions.sort(compareOptionNames)

    return {
      productRows,
      productOptions
    }
  }

  useEffect(() => {
    const fetchOptions = async (): Promise<void> => {
      let distributorOptions: IOption[] = []
      let brandOptions: IOption[] = []
      let productOptions: IOption[] = []
      let productRows: IProductDataRow[] = []

      try {
        const foodtasticBrandOptions = await getFoodtasticBrandOptions()
        distributorOptions = await getDistributorOptions()
        brandOptions = await getBrandOptions(supplierId)
        const products = await getProducts(supplierId)
        const rowsAndOptions = await getProductRowsAndOptions(products)
        productOptions = rowsAndOptions.productOptions
        productRows = rowsAndOptions.productRows
        const deliveryMethodOptions = [
          {
            id: EDeliveryMethods.DISTRIBUTOR,
            name: `${t('supplierContractForm.contractedPrices.section.tableOptions.deliveryMethod1')}`
          },
          {
            id: EDeliveryMethods.PICK_UP,
            name: `${t('supplierContractForm.contractedPrices.section.tableOptions.deliveryMethod2')}`
          }
        ]
        const paymentFrequencyOptions: IOption[] = [
          {
            id: EPaymentFrequency.MONTHLY,
            name: t('supplierContractForm.commons.paymentConditions.frequencyOptions.monthly')
          },
          {
            id: EPaymentFrequency.QUARTERLY,
            name: t('supplierContractForm.commons.paymentConditions.frequencyOptions.quarterly')
          },
          {
            id: EPaymentFrequency.BI_YEARLY,
            name: t('supplierContractForm.commons.paymentConditions.frequencyOptions.biyearly')
          },
          {
            id: EPaymentFrequency.YEARLY,
            name: t('supplierContractForm.commons.paymentConditions.frequencyOptions.yearly')
          }
        ]
        const measurementTypeOptionsVolumeRebate: IOption[] = [
          {
            id: EMeasurementType.CASE,
            name: t('supplierContractForm.commons.measurementType.options.cases')
          },
          {
            id: EMeasurementType.DOLLARS,
            name: t('supplierContractForm.commons.measurementType.options.dollars')
          },
          {
            id: EMeasurementType.WEIGHT_KG,
            name: t('supplierContractForm.commons.measurementType.options.kg')
          },
          {
            id: EMeasurementType.WEIGHT_G,
            name: t('supplierContractForm.commons.measurementType.options.g')
          },
          {
            id: EMeasurementType.WEIGHT_L,
            name: t('supplierContractForm.commons.measurementType.options.l')
          },
          {
            id: EMeasurementType.WEIGHT_ML,
            name: t('supplierContractForm.commons.measurementType.options.ml')
          },
          {
            id: EMeasurementType.WEIGHT_LBS,
            name: t('supplierContractForm.commons.measurementType.options.lbs')
          }
        ]

        const measurementTypeOptionsUnitRebate: IOption[] = [
          {
            id: EMeasurementType.CASE,
            name: t('supplierContractForm.commons.measurementType.options.cases')
          },
          {
            id: EMeasurementType.PERCENTAGE,
            name: t('supplierContractForm.commons.measurementType.options.percentage')
          },
          {
            id: EMeasurementType.WEIGHT_KG,
            name: t('supplierContractForm.commons.measurementType.options.dollarPerKg')
          },
          {
            id: EMeasurementType.WEIGHT_G,
            name: t('supplierContractForm.commons.measurementType.options.dollarPerG')
          },
          {
            id: EMeasurementType.WEIGHT_L,
            name: t('supplierContractForm.commons.measurementType.options.dollarPerL')
          },
          {
            id: EMeasurementType.WEIGHT_ML,
            name: t('supplierContractForm.commons.measurementType.options.dollarPerMl')
          },
          {
            id: EMeasurementType.WEIGHT_LBS,
            name: t('supplierContractForm.commons.measurementType.options.dollarPerLbs')
          }
        ]
        const setOptions = {
          type: EOptionsMenuStateType.SET_OPTIONS,
          payload: {
            foodtasticBrandOptions,
            distributionCenterOptions: distributorOptions,
            brandOptions,
            productOptions,
            productRows,
            deliveryMethodOptions,
            paymentFrequencyOptions,
            measurementTypeOptionsVolumeRebate,
            measurementTypeOptionsUnitRebate
          }
        }
        dispatch(setOptions)
      } catch (error) {
        console.error('Error fetching contract list:', error)
        const setError = {
          type: EOptionsMenuStateType.SET_ERROR,
          payload: {
            loadingError: true
          }
        }
        dispatch(setError)
      }
    }

    fetchOptions().catch((error) => { console.error('Error during fetchData:', error) })
  }, [])

  return (
    <OptionsContext.Provider value={{ state, dispatch }}>
      {children}
    </OptionsContext.Provider>
  )
}

export const useOptions: () => IOptionsMenuContext = () => {
  return useContext(OptionsContext)
}
