import React from 'react'
import { Typography, Box, Grid, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Content } from '../layout'

export const ErrorComponent: React.FC<{ message: string }> = ({ message }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleNavigateBackToList = (): void => {
    navigate('/')
  }

  return (
    <Content
      header={<></>}
      body={
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box color="red" textAlign="center" padding="10px">
            <Typography variant="body1">
              {message}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleNavigateBackToList}>
            {t('commons.buttons.goToList')}
          </Button>
        </Grid>
      </Grid>
      }
      footer={<></>}
    />
  )
}
