import { IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useTranslation } from 'react-i18next'

export interface IHeader {
  key: string
  displayValue: string
}

interface ISimpleTableProps {
  headers: IHeader[]
  columnWidths?: string[]
  rows: Array<Record<string, React.ReactNode>>
  onDeleteRow?: (rowIndex: number) => void
  onCloneRow?: (rowIndex: number) => void
}

export const SimpleTable: React.FC<ISimpleTableProps> = ({
  headers,
  rows,
  columnWidths,
  onDeleteRow = null,
  onCloneRow = null
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const { t } = useTranslation()

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number): void => {
    setAnchorEl(event.currentTarget)
    setSelectedIndex(index)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
    setSelectedIndex(null)
  }

  const handleDelete = (): void => {
    if (onDeleteRow !== null && selectedIndex !== null) {
      onDeleteRow(selectedIndex)
    }
    handleClose()
  }

  const handleClone = (): void => {
    if (onCloneRow !== null && selectedIndex !== null) {
      onCloneRow(selectedIndex)
    }
    handleClose()
  }

  const showMenu: boolean = (onDeleteRow !== null || onCloneRow !== null)

  return (
    <TableContainer component={Paper}>
      <Table style={{ tableLayout: 'fixed' }}>
        <colgroup>
          {columnWidths?.map((width, index) => (
            <col key={index} style={{ width }} />
          ))}
          {showMenu && <col style={{ width: 'auto' }} />}
        </colgroup>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>{header.displayValue}</TableCell>
            ))}
            {showMenu && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {headers.map((header, colIndex) => (
                <TableCell key={colIndex}>
                  <div
                    style={{
                      maxHeight: '150px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical'
                    }}
                  >
                      {row[header.key]}
                  </div>
                </TableCell>
              ))}
              {showMenu && (
                <TableCell>
                  <IconButton aria-label="more" onClick={(e) => { handleClick(e, rowIndex) }}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {onCloneRow !== null && (
                      <MenuItem onClick={handleClone}>{t('components.simpleTable.clone')}</MenuItem>
                    )}
                    {onDeleteRow !== null && (
                      <MenuItem onClick={handleDelete}>{t('components.simpleTable.delete')}</MenuItem>
                    )}
                  </Menu>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
