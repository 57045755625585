import { type ISupplierContractFormAction, type ISupplierContractFormState, ESupplierContractFormActionType, type ILumpSum, type IVolumeRebates, type IUnitRebates, type IContractedPrices, type ISummary, type IContractForm, type ILoadingError, type IContractFormErrors, type IContractInformation } from '../types'

export const supplierContractFormReducer: (state: ISupplierContractFormState, action: ISupplierContractFormAction) => ISupplierContractFormState = (state, action) => {
  let payload = null
  switch (action.type) {
    case ESupplierContractFormActionType.SET_LOADING_ERROR:
      payload = action.payload as ILoadingError
      return {
        ...state,
        errorLoadingState: payload.errorLoadingState,
        loading: false
      }
    case ESupplierContractFormActionType.LOAD_CONTRACT:
      payload = action.payload as IContractForm
      return {
        ...state,
        loading: false,
        contractForm: {
          ...payload
        }
      }
    case ESupplierContractFormActionType.UPDATE_CONTRACT_INFORMATION:
      payload = action.payload as IContractInformation
      return {
        ...state,
        contractForm: {
          ...state.contractForm,
          supplierContact: { ...payload.supplierContact },
          contact: { ...payload.contact },
          contractStartDate: payload.contractStartDate,
          contractEndDate: payload.contractEndDate,
          hasLumpSumRebate: payload.hasLumpSumRebate,
          hasContractedPrice: payload.hasContractedPrice,
          hasVolumeRebate: payload.hasVolumeRebate,
          hasUnitRebate: payload.hasUnitRebate,
          contractedPrices: payload.hasContractedPrice ? [...state.contractForm.contractedPrices] : [],
          lumpSumRebates: payload.hasLumpSumRebate ? [...state.contractForm.lumpSumRebates] : [],
          volumeRebates: payload.hasVolumeRebate ? [...state.contractForm.volumeRebates] : [],
          unitRebates: payload.hasUnitRebate ? [...state.contractForm.unitRebates] : [],
          unitRebatesFirstPaymentIssuedDate: payload.hasUnitRebate ? state.contractForm.unitRebatesFirstPaymentIssuedDate : '',
          unitRebatesPaymentFrequencyId: payload.hasUnitRebate ? state.contractForm.unitRebatesPaymentFrequencyId : null,
          volumeRebatesFirstPaymentIssuedDate: payload.hasVolumeRebate ? state.contractForm.volumeRebatesFirstPaymentIssuedDate : '',
          volumeRebatesPaymentFrequencyId: payload.hasVolumeRebate ? state.contractForm.volumeRebatesPaymentFrequencyId : null
        }
      }
    case ESupplierContractFormActionType.UPDATED_CONTRACTED_PRICES:
      payload = action.payload as IContractedPrices
      return {
        ...state,
        contractForm: {
          ...state.contractForm,
          hasContractedPrice: payload.hasContractedPrice,
          contractedPrices: payload.hasContractedPrice ? [...payload.contractedPrices] : []
        }
      }
    case ESupplierContractFormActionType.UPDATE_LUMP_SUM:
      payload = action.payload as ILumpSum
      return {
        ...state,
        contractForm: {
          ...state.contractForm,
          hasLumpSumRebate: payload.hasLumpSumRebate,
          lumpSumRebates: payload.hasLumpSumRebate ? [...payload.lumpSumRebates] : []
        }
      }
    case ESupplierContractFormActionType.UPDATE_VOLUME_REBATES:
      payload = action.payload as IVolumeRebates
      return {
        ...state,
        contractForm: {
          ...state.contractForm,
          hasVolumeRebate: payload.hasVolumeRebate,
          volumeRebates: payload.hasVolumeRebate ? [...payload.volumeRebates] : [],
          volumeRebatesFirstPaymentIssuedDate: payload.hasVolumeRebate && payload.volumeRebates.length > 0 ? payload.volumeRebatesFirstPaymentIssuedDate : '',
          volumeRebatesPaymentFrequencyId: payload.hasVolumeRebate && payload.volumeRebates.length > 0 ? payload.volumeRebatesPaymentFrequencyId : null
        }
      }
    case ESupplierContractFormActionType.UPDATE_UNIT_REBATES:
      payload = action.payload as IUnitRebates
      return {
        ...state,
        contractForm: {
          ...state.contractForm,
          hasUnitRebate: payload.hasUnitRebate,
          unitRebates: payload.hasUnitRebate ? [...payload.unitRebates] : [],
          unitRebatesFirstPaymentIssuedDate: payload.hasUnitRebate && payload.unitRebates.length > 0 ? payload.unitRebatesFirstPaymentIssuedDate : '',
          unitRebatesPaymentFrequencyId: payload.hasUnitRebate && payload.unitRebates.length > 0 ? payload.unitRebatesPaymentFrequencyId : null
        }
      }
    case ESupplierContractFormActionType.UPDATE_SUMMARY_DATA:
      payload = action.payload as ISummary
      return {
        ...state,
        contractForm: {
          ...state.contractForm,
          comments: payload.comments,
          attachments: payload.attachments,
          signature: payload.signature
        }
      }
    case ESupplierContractFormActionType.VALIDATE_FORM:
      payload = action.payload as IContractFormErrors
      return {
        ...state,
        contractFormErrors: payload
      }
    default:
      return state
  }
}
