import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../../stores/SupplierContractForm'
import { type IHeader, SimpleTable } from '../../../commons'
import { getOptionNamesByIds } from '../../../../utils'
import { useOptions } from '../../../../stores/Options'

export const ContractedPricesSummary: React.FC = () => {
  const { state: options } = useOptions()
  const { t } = useTranslation()
  const { state } = useSupplierForm()
  const contractForm = state.contractForm
  const contractedPrices = contractForm.contractedPrices

  const headers: IHeader[] = [
    {
      key: 'productGTIN',
      displayValue: t('supplierContractForm.contractedPrices.section.headers.productGTIN')
    },
    {
      key: 'productCode',
      displayValue: t('supplierContractForm.contractedPrices.section.headers.productCode')
    },
    {
      key: 'productDescription',
      displayValue: t('supplierContractForm.contractedPrices.section.headers.productDescription')
    },
    {
      key: 'minimumVolume',
      displayValue: t('supplierContractForm.contractedPrices.section.headers.minimumVolume')
    },
    {
      key: 'contractedPrice',
      displayValue: t('supplierContractForm.contractedPrices.section.headers.contractedPrice')
    },
    {
      key: 'distributorCenters',
      displayValue: t('supplierContractForm.contractedPrices.section.headers.distributorCenters')
    },
    {
      key: 'startDate',
      displayValue: t('supplierContractForm.contractedPrices.section.headers.startDate')
    },
    {
      key: 'expirationDate',
      displayValue: t('supplierContractForm.contractedPrices.section.headers.expirationDate')
    },
    {
      key: 'deliveryMethod',
      displayValue: t('supplierContractForm.contractedPrices.section.headers.deliveryMethod')
    }
  ]

  const rowsData: Array<Array<Record<string, React.ReactNode>>> = contractedPrices.map((entry) => {
    const rows = Array.isArray(entry.contractedPriceData) ? entry.contractedPriceData : []
    return rows.filter((row) => row.contractedPrice !== null).map((row) => ({
      productGTIN: row.productGTIN,
      productCode: row.productCode,
      productDescription: row.productDescriptionEN,
      minimumVolume: row.minimumVolume,
      contractedPrice: row.contractedPrice,
      distributorCenters: getOptionNamesByIds(options.distributionCenterOptions, row.distributionCenterIds),
      startDate: row.dateInEffect,
      expirationDate: row.expirationDate,
      deliveryMethod: getOptionNamesByIds(options.deliveryMethodOptions, row.deliveryMethodId !== null ? [row.deliveryMethodId] : [])
    }))
  })

  return (
    <Grid container spacing={2}>
      {contractedPrices.map((entry, index) => (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>{
              `${t('supplierContractForm.summary.commons.applicableTo')} ${options.foodtasticBrandOptions.length === entry.foodtasticBrandIds.length ? t('supplierContractForm.commons.allFoodtasticBrands') : getOptionNamesByIds(options.foodtasticBrandOptions, entry.foodtasticBrandIds)}`}
            </Typography>
          </Grid>
          {entry.contractedPriceData.length > 0 &&
            <Grid item xs={12}>
              <SimpleTable
                headers={headers}
                rows={rowsData[index]}
              />
            </Grid>
          }
        </>
      ))}
    </Grid>
  )
}
