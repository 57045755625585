import type { MenuProps, SxProps } from '@mui/material'

export const selectMenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: 200
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
}

export const widgetProps: SxProps = {
  backgroundColor: '#FFFCFC',
  border: '1px solid #ddd'
}
