import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig as msalConfigDev, loginRequest as loginrequestDev } from '../../authConfig.dev'
import { msalConfig as msalConfigProd, loginRequest as loginrequestProd } from '../../authConfig.prod'
import { type AxiosRequestConfig } from 'axios'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 */
const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'dev'
const msalConfig = isDevelopment ? msalConfigDev : msalConfigProd
export const msalInstance = new PublicClientApplication(msalConfig)
const baseURL = process.env.REACT_APP_BASE_URL
const loginRequest = isDevelopment ? loginrequestDev : loginrequestProd

export const login = async (): Promise<void> => {
  try {
    const loginResponse = await msalInstance.loginPopup({ ...loginRequest })
    msalInstance.setActiveAccount(loginResponse.account)
  } catch (error) {
    console.log(error)
  }
}

export const logout = async (): Promise<void> => {
  try {
    await msalInstance.logoutPopup({ mainWindowRedirectUri: '/' })
    msalInstance.setActiveAccount(null)
  } catch (error) {
    console.log(error)
  }
}

export const getToken = async (): Promise<string> => {
  const account = msalInstance.getActiveAccount()
  if (account == null) {
    await login()
    return ''
  }

  const request = {
    scopes: loginRequest.scopes,
    account
  }

  try {
    const response = await msalInstance.acquireTokenSilent(request)
    return response.accessToken
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getRequestConfig = async (): Promise<object> => {
  try {
    const token = await getToken()
    const config: AxiosRequestConfig = {
      baseURL,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    return config
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const initializeMsal = async (): Promise<void> => {
  try {
    await msalInstance.initialize() // addresses this issue https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#uninitialized_public_client_application
  } catch (error) {
    console.log(error)
    throw error
  }
}
