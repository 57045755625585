import type { IContractFormErrors } from '../types'

export const contractFormErrorsInitialState: IContractFormErrors = {
  supplierContact: {
    phone: false,
    email: false
  },
  contact: {
    phone: false,
    email: false
  },
  contractStartDate: false,
  contractEndDate: false,
  contractedPrices: {},
  lumpSumRebates: {},
  volumeRebates: {},
  unitRebates: {}
}
