import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSupplierForm } from '../../../../stores/SupplierContractForm'
import { getOptionNameById } from '../../../../utils'
import { useOptions } from '../../../../stores/Options'

export const LumpSumSummary: React.FC = () => {
  const { t } = useTranslation()
  const { state } = useSupplierForm()
  const { state: options } = useOptions()
  const contractForm = state.contractForm
  const lumpSumRebates = contractForm.lumpSumRebates

  return (
    <Grid container spacing={2}>
      {lumpSumRebates.map((entry, index) => (
        <>
          <Grid item xs={12}>
            <Typography fontWeight="bold" variant="subtitle1">{`${t('supplierContractForm.summary.lumpSum.title')} ${index + 1}`}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">{t('supplierContractForm.summary.lumpSum.subtitle.amount')}</Typography>
            <Typography variant="body1">{entry.lumpSumAmount}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">{t('supplierContractForm.summary.lumpSum.subtitle.payment')}</Typography>
            <Typography variant="body1">{getOptionNameById(options.paymentFrequencyOptions, entry.paymentFrequencyId ?? '') ?? '-'}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">{t('supplierContractForm.summary.lumpSum.subtitle.firstPaymentDate')}</Typography>
            <Typography variant="body1">{entry.firstPaymentIssuedDate}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">{t('supplierContractForm.summary.lumpSum.subtitle.firstPaymentAmount')}</Typography>
            <Typography variant="body1">{entry.firstPaymentAmount}</Typography>
          </Grid>
        </>
      ))}
    </Grid>
  )
}
