import type { IProductDataRow, IOption } from '../types'

export const compareOptionNames = (a: IOption, b: IOption): number => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

export const compareGTIN = (a: IProductDataRow, b: IProductDataRow): number => {
  if (a.productGTIN < b.productGTIN) {
    return -1
  }
  if (a.productGTIN > b.productGTIN) {
    return 1
  }
  return 0
}

export const join = (separator: string, ...values: Array<string | null | undefined>): string => {
  const result = values.filter(value => value !== null && value !== undefined && value !== '').join(separator)
  return result !== '' ? result : '-'
}

export const getOptionNamesByIds = (options: IOption[], ids: string[]): string => {
  const filteredOptions = options.filter(option => ids.includes(option.id))
  const names = filteredOptions.map(option => option.name)
  return names.join(', ')
}

export const getOptionNameById = (options: IOption[], id: string): string | null => {
  const option = options.find(option => option.id === id || +option.id === +id)
  return option?.name ?? null
}

export const isEmpty = (val: string): boolean => {
  return (val === undefined || val == null || val.length <= 0)
}

export const isValidPhoneNumber = (number: string): boolean => {
  const phoneRegex = /^\d{10}$/
  return phoneRegex.test(number)
}

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const formatDate = (date: string): string => {
  if (isEmpty(date)) {
    return ''
  }
  return new Date(date).toISOString().split('T')[0]
}
